import {AfterViewChecked, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {CommandeService} from "../../services/epona/commande.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {Article} from "../../model/epona-api/Article";
import {Lieu} from "../../model/epona-api/Lieu";
import {FormGroupDirective} from "@angular/forms";
import {CanDeactivateComponent} from "../../intercepteurs/can-deactivate/can-deactivate.component";
import {CommandeEnteteComponent} from "../commande-entete/commande-entete.component";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {EntreeSortieSearch} from "../../model/epona-api/EntreeSortieSearch";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {Observable, Subscription} from "rxjs";
import {CommandeFinalisation} from "../../model/epona-ui/CommandeFinalisation";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {FormTools} from "../../commons/FormTools";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {DesignationArticlePipe} from "../../commons/pipes/designation-article.pipe";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {Const} from "../../commons/constants/Const";

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.css']
})
export class CommandeComponent extends CanDeactivateComponent implements AfterViewChecked {
  idCommande: number;
  lieuCourant: Lieu;
  entete: CommandeEntete;
  lignes: Array<CommandeLigne>;
  listeBordereauxLivraisonLies: Array<EntreeSortieEntete>;
  modificationLigneEnCours: boolean = false;

  droitModification: boolean;
  private droitConsultationBL: boolean;

  private readonly FIELDS_ENTETE = '' +
    'idCommandeEntete,' +
    'lieuDemandeur.idLieu,' +
    'etatCommande,' +
    'fournisseur.idFournisseur,' +
    'fournisseur.nom,' +
    'fournisseur.possedeSitePunchout,' +
    'sousLotZg.idSousLotZg,' +
    'sousLotZg.codeMarcheOrion,' +
    'sousLotZg.numeroSousLot,' +
    'sousLotZg.typeSousLot,' +
    'sousLotZg.francoPort,' +
    'sousLotZg.fraisPort,' +
    'sousLotZg.uniteFrancoPort,' +
    'sousLotZg.montantMaxFraisPort,' +
    'sousLotZg.detailFraisPort,' +
    'externe,' +
    'dateLivraisonSouhaitee,' +
    'numeroEj,' +
    'envoiEdi,' +
    'idPanierEntete,' +
    'nombreClientsEstimatif,' +
    'fraisPortPrevisionnels,' +
    'informationsLivraison,' +
    'instructionsCommande,' +
    'codeClientDemandeur,' +
    'dateCreation,utilisateurCreation,' +
    'dateModification,utilisateurModification,' +
    'nbLignes,' +
    'extra.deletable,' +
    'extra.envoyableMagasin,' +
    'extra.preparationFinalisable,' +
    'extra.refusableMagasin,' +
    'extra.receptionnable,' +
    'extra.receptionFinalisable,' +
    'extra.commandeFinalisable,' +
    'extra.finalisationCommandeAnnulable,' +
    'extra.ejEnvoyable,' +
    'extra.duplicable' +
    '';

  private readonly FIEDLS_LIGNE = 'idCommandeLigne,' +
    'article.idArticle,' +
    'article.articleAchat,' +
    'article.articleVente,' +
    'article.codeArticleAchat,' +
    'article.codeArticleVente,' +
    'article.designationAchat,' +
    'article.designationVente,' +
    'article.pcb,' +
    'article.commandableExterne,' +
    'article.sousGroupe,' +
    'article.familleMarches,' +
    'article.origineAchat,' +
    'article.commentaireParametrage,' +
    'colisage,' +
    'reference,' +
    'quantite,' +
    'uniteConditionnement.abreviation,' +
    'prixAchatHt,' +
    'tauxTva,' +
    'prixAchatTtc,' +
    'valeurHt,' +
    'commentaire,' +
    'quantiteMagasin,' +
    'commentaireMagasin,' +
    'quantiteReceptionnee,' +
    'dateMajPrix,' +
    'estPrixRecent,' +
    'ageMaxPrix' +
    '';

  private readonly FIELDS_BORDEREAU_LIVRAISON_LIE = 'idEntreeSortieEntete,lieu.nom,dateBordereauLivraison,codeBordereauLivraison';

  private readonly ONLY_META_DATA = true;

  formGroupDirective: FormGroupDirective;

  navigationSubscription: Subscription;
  currentUrl: string;

  isInvalidFormLignes: boolean;
  utilisation: string;

  // Permet d'accèder aux données du composant enfant afin de contrôler s'il y a eu des modifications si changement de page
  // ViewChild est une réquête dynamique : il sera toujours à jour du template !
  @ViewChild(CommandeEnteteComponent)
  set element(element: CommandeEnteteComponent) {
    this.formGroupDirective = element.form;
  }

  constructor(private commandeService: CommandeService,
              private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool,
              private router: Router,
              private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private designationPipe: DesignationArticlePipe) {
    super();

    // Nécessaire pour la redirection suite à une duplication de commande (même route, commande différente)
    // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        // Pas de rechargement si l'URL reste la même (cas d'une sortie de la page non confirmée)
        if (this.currentUrl !== e.url) {
          this.currentUrl = e.url;
          this.init();
        }
      }
    });

    this.droitModification = this.commandeService.droitModification();
    this.droitConsultationBL = this.entreeSortieService.droitConsultation(EntreeSortieParametrage.BORDEREAUX_LIVRAISON);
  }

  init() {
    // Récupération du paramètre idCommande dans l'url.
    // Pas besoin d'utiliser l'observable paramMap car on n'a pas besoin que le ngOnInit soit rappelé
    this.idCommande = parseInt(this.route.snapshot.paramMap.get('id'));

    if (this.idCommande) {
      this.loadCommande();
    } else {
      this.lieuCourant = new Lieu();
      this.lieuCourant.initWithLocalStorage();
    }
  }

  ngOnDestroy() {
    // Pour éviter de continuer à exécuter la méthode init() sur chaque événement navigationEnd.
    // Si on ne le fait pas, la redirection vers n'importe quelle route appel la méthode init() !!!
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  // Pour résoudre l'erreur Expression has changed after it was checked. Previous value: 'undefined'. Current value: 'true'
  // lors de la désacativation des lignes lors de l'envoi d'une commande
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private loadCommande(): void {
    this.getEntete();
    this.getLignes();
    this.getListeBordereauxLivraisonLies();
  }

  private getEntete(onlyMetaData: boolean = false): void {
    this.commandeService.getEntete(this.idCommande, this.FIELDS_ENTETE).subscribe(data => {
      if(onlyMetaData) {
        this.entete.dateModification = data.dateModification;
        this.entete.utilisateurModification = data.utilisateurModification;
        this.entete.extra = data.extra;

      } else {
        this.entete = data;
        this.lieuCourant = data.lieuDemandeur;
        this.utilisation = this.estExterne() ? UtilisationArticle.ACHAT : UtilisationArticle.VENTE;
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  estExterne(): boolean {
    return this.entete && this.entete.externe === true;
  }

  estEnvoyee(): boolean {
    return this.entete
      &&  (this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.EN_PREPARATION
        || this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.EN_ACHEMINEMENT
        || this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.EN_RECEPTION
        || this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.RECEPTIONNEE);
  }

  private getLignes(listeArticlesToHighlight: Array<Article> = null): void {
    const search = new CommandeLigneSearch();
    search.fields = this.FIEDLS_LIGNE;

    this.commandeService.getListeLignes(this.idCommande, search).subscribe(data => {
      this.lignes = data;
      // Ordonnancement par désignation de l'article
      this.lignes.sort((a, b) => {
        const designationA = this.designationPipe.transform(a.article, this.utilisation);
        const designationB = this.designationPipe.transform(b.article, this.utilisation);
        return designationA.localeCompare(designationB);
      });

      if (listeArticlesToHighlight) {
        listeArticlesToHighlight.forEach(article => {
          this.lignes.forEach(ligne => {
            if (ligne.article.idArticle === article.idArticle
              || ligne.article.codeArticleVente && article.codeArticleVente && ligne.article.codeArticleVente === article.codeArticleVente) {
              FormTools.markAsSuccess(ligne);
            }
          });
        });
      }

      // Le colisage est copié dans le PCB afin de profiter de la règle de gestion
      this.lignes.forEach(l => {
        l.article.pcb = l.colisage ? l.colisage : l.article.pcb;
      })

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private getListeBordereauxLivraisonLies(): void {
    if (this.droitConsultationBL) {
      const search = new EntreeSortieSearch();
      search.idCrous = +localStorage.getItem('idCrous');
      search.idCommande = this.idCommande;
      search.fields = this.FIELDS_BORDEREAU_LIVRAISON_LIE;

      this.entreeSortieService.getListeEntetes(search).subscribe(data => {
        this.listeBordereauxLivraisonLies = data.length > 0 ? data : null;

      }, err => {
        this.listeBordereauxLivraisonLies = null;
        this.messageTool.sendError(err);
        console.error(err);
      });
    } else {
      this.listeBordereauxLivraisonLies = null;
    }
  }

  // Récupération du composant enfant (entete) de l'entête à créer ou à modifier
  doActionEntete(entete: CommandeEntete) {
    if (this.idCommande) {
      this.doModificationEntete(entete);
    } else {
      this.doCreationEntete(entete);
    }
  }

  private doCreationEntete(entete: CommandeEntete) {
    this.commandeService.postEntete(entete).subscribe(data => {
      this.messageTool.sendSuccess("La commande a été enregistrée avec succès", ClearMessages.TRUE);
      this.resetFormStates();

      this.router.navigate(['/commande', data.idCommandeEntete]);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private doModificationEntete(entete: CommandeEntete) {
    entete.idCommandeEntete = this.idCommande;

    if (this.commandeService.estBrouillon(this.entete)) {
      this.commandeService.putEntete(entete).subscribe(() => {
        this.handleModification();
      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      this.commandeService.patchEntete(entete).subscribe(() => {
        this.handleModification();
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private handleModification() {
    this.getEntete();
    this.messageTool.sendSuccess("La commande a été modifiée avec succès", ClearMessages.TRUE);
    this.resetFormStates();
  }

  // Récupération du composant enfant (entête) du BL que l'on doit générer
  doGenerationBL(bl: EntreeSortieEntete) {
    bl.idLogiciel = Const.ID_LOGICIEL_EPONA;

    this.entreeSortieService.postEntete(bl).subscribe(data => {
      this.messageTool.sendSuccess(`Le bordereau de livraison no. ${data.idEntreeSortieEntete} a été généré`, ClearMessages.TRUE);

      this.router.navigate(['/bordereau-livraison', data.idEntreeSortieEntete]);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entête) si on doit faire la suppression de la commande
  doSuppression(doSuppression: boolean) {
    if (doSuppression) {
      this.commandeService.deleteCommande(this.idCommande).subscribe(() => {
        this.messageTool.sendSuccess("La commande a été supprimée avec succès", ClearMessages.TRUE);
        this.resetFormStates();

        // Redirection vers la liste des commandes
        this.redirectionListeCommandes();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  // Récupération du composant enfant (entête) si on doit faire l'envoi de la commande
  doEnvoi(doEnvoi: boolean) {
    if (doEnvoi) {
      this.commandeService.postEnvoiMagasin(this.idCommande).subscribe(() => {
        this.messageTool.sendSuccess(`La commande no. ${this.idCommande} a été envoyée avec succès`, ClearMessages.TRUE);
        this.resetFormStates();

        // L'utilisateur est redirigé vers la liste des commandes internes à l'état "En préparation" sur le lieu courant
        this.redirectionListeCommandes(CodeEtatCommande.EN_PREPARATION);

      }, err => {
        this.messageTool.sendError(err);
      })

    }
  }

  // Récupération du composant enfant (entête) si on doit faire le refus de la commande
  doRefus(doRefus: boolean) {
    if (doRefus) {
      this.commandeService.postRefusMagasin(this.idCommande).subscribe(() => {
        this.messageTool.sendSuccess(`La commande no. ${this.idCommande} a été refusée avec succès`, ClearMessages.TRUE);
        this.resetFormStates();

        this.redirectionListeCommandes(CodeEtatCommande.REFUSEE_MAGASIN);

      }, err => {
        this.messageTool.sendError(err);
      })

    }
  }

  // Récupération du composant enfant (entête) si on doit faire une finalisation de commande
  doFinalisationCommande(doFinalisationCommande: boolean) {
    if (doFinalisationCommande) {
      this.commandeService.postFinalisationCommande(this.idCommande).subscribe(() => {
        const message = `La finalisation de la commande no. ${this.idCommande} a été prise en compte`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);
        this.getEntete();
      }, err => {
        this.messageTool.sendError(err);
      })
    }
  }

  // Récupération du composant enfant (entête) si on doit annuler la finalisation de la commande
  doAnnulationFinalisationCommande(doAnnulationFinalisationCommande: boolean) {
    if (doAnnulationFinalisationCommande) {
      this.commandeService.postAnnulationFinalisationCommande(this.idCommande).subscribe(() => {
        const message = `La finalisation de la commande no. ${this.idCommande} a été annulée`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);
        this.getEntete();
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  doHandleEnvoiEngagementJuridique() {
      // L'envoi est effectué par la dialog de saisie de l'EJ (cf. DialogEngagementJuridiqueComponent)
      this.getEntete();
  }

  doFinalisationPreparationReception(finalisation: CommandeFinalisation) {
    if (finalisation) {
      const estFinalisationPreparation = finalisation.typeFinalisation === CommandeFinalisation.TYPE_FINALISATION.PREPARATION;

      let observable: Observable<any>;

      if (estFinalisationPreparation) {
        observable = this.commandeService.postFinalisationPreparation(this.idCommande);
      } else {
        observable = this.commandeService.postFinalisationReception(this.idCommande, {commentaire: finalisation.commentaire});
      }

      observable.subscribe(() => {
        const message = `La ${finalisation.typeFinalisation} de la commande no. ${this.idCommande} a été finalisée avec succès`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);

        const codeEtat = estFinalisationPreparation ? CodeEtatCommande.EN_ACHEMINEMENT : CodeEtatCommande.RECEPTIONNEE;
        this.redirectionListeCommandes(codeEtat);

      }, err => {
        this.messageTool.sendError(err);
      })
    }
  }

  doDuplication(doDuplication: boolean) {
    if (doDuplication) {
      this.commandeService.postDuplication(this.idCommande).subscribe(data => {
        this.messageTool.sendSuccess(`La commande  ${data.idCommandeEntete} a été créée`, ClearMessages.TRUE);

        this.router.navigate(['/commande', data.idCommandeEntete]);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  // Récupération du composant enfant (lignes) qu'un ou plusieurs articles ont été ajoutés
  doHandleListeArticlesInserted(listeArticles: Array<Article>) {
    // L'entête est actualisée complètement s'il s'agit de la première ligne (informations marché renseignées)
    const onlyMetaData = this.lignes.length > 0;
    this.getEntete(onlyMetaData);
    this.getLignes(listeArticles);
  }

  // Récupération du composant enfant (lignes) qu'une ligne a été modifiée
  doHandleLigneUpdated(ligne: CommandeLigne) {
    this.getEntete(this.ONLY_META_DATA);
  }

  // Récupération du composant enfant (lignes) que des lignes ont été supprimées
  doHandleLignesDeleted(lignes: Array<CommandeLigne>) {
    // L'entête est actualisée complètement si toutes les lignes sont supprimées (informations marché vidées)
    const onlyMetaData = this.lignes.length > lignes.length;
    this.getEntete(onlyMetaData);
    this.getLignes();
  }

  getIsInvalidFormLignes(isInvalid: boolean) {
    this.isInvalidFormLignes = isInvalid;
  }

  doDebutModificationLigne() {
    this.modificationLigneEnCours = true;
  }
  doFinModificationLigne() {
    this.modificationLigneEnCours = false;
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onCourrielChanged() {
    this.getEntete(this.ONLY_META_DATA);
  }

  private redirectionListeCommandes(codeEtat?: number): void {
    this.router.navigate(['liste-commandes'], {
      queryParams: {
        idLieu: this.lieuCourant.idLieu,
        externe: this.entete.externe,
        codesEtat: codeEtat
      }
    });
  }

  /* **************** */
  /* Gestion des FDNC */
  /* **************** */

  private readonly TAB_INDEX_FDNC = 1;
  displayFdnc = false;
  indexChange(index: number) {
    if (index === this.TAB_INDEX_FDNC) {
      this.displayFdnc = true;  // Pour que les FDNC ne soit chargées qu'à la sélection de l'onglet
    }
  }
}
