<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Commandes générées</span>
    <span class="space"></span>
    <button type="button" mat-icon-button mat-dialog-close aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <p *ngIf="data.listeCommandes.length === 1">La commande suivante a été générée&nbsp;:</p>
  <p *ngIf="data.listeCommandes.length > 1">Les commandes suivantes ont été générées&nbsp;:</p>

  <ul>
    <li *ngFor="let commande of data.listeCommandes">
      <a [routerLink]="['/commande', commande.idCommandeEntete]" (click)="close()">
        Commande no. {{ commande.idCommandeEntete }} [{{ commande.sousLotZg | lotMarcheOrionType }}]
      </a>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="close()">Fermer</button>
</mat-dialog-actions>
