import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Stock} from "../../model/epona-api/Stock";
import {StocksFilter} from "../../model/epona-ui/StocksFilter";
import {HttpService} from "../http.service";
import {SousLotZgSearch} from "../../model/epona-api/SousLotZgSearch";
import {SousLotZg} from "../../model/epona-api/SousLotZg";
import {ArticleMarcheSearch} from "../../model/epona-api/ArticleMarcheSearch";
import {ArticleMarche} from "../../model/epona-api/ArticleMarche";
import {Marche} from "../../model/epona-api/Marche";

@Injectable()
export class EponaService {

  private env = environment;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
  }

  /* ******************************************************
     *********************** STOCKS ***********************
     ****************************************************** */

  getStocks(stockFilter: StocksFilter): Observable<Stock[]> {
    const params = this.httpService.buildParams(stockFilter);

    return this.httpClient.get<Stock[]>(this.env.eponaApiUrl + '/stocks',
      {params: params});
  }

  /* ******************************************************
     *********************** MARCHES **********************
     ****************************************************** */

  getListeMarches(): Observable<Marche[]> {
    return this.httpClient.get<Marche[]>(this.env.eponaApiUrl + '/marches');
  }

  /* ******************************************************
     ********************** SOUS-LOTS *********************
     ****************************************************** */

  getListeSousLotsZg(search: SousLotZgSearch): Observable<SousLotZg[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<SousLotZg[]>(this.env.eponaApiUrl + '/sous-lots-zone-geographique', {params});
  }

  /* ******************************************************
     ******************* ARTICLES-MARCHE ******************
     ****************************************************** */

  getListeArticlesMarche(search: ArticleMarcheSearch): Observable<ArticleMarche[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<ArticleMarche[]>(this.env.eponaApiUrl + '/articles-marche', {params});
  }
}
