import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OnlineService {

  isOnline = new BehaviorSubject<boolean>(window.navigator.onLine);

  constructor(private httpClient: HttpClient) {
    //this.listenToOnlineStatus();
  }

  testConnexion() : Observable<boolean>{
    return this.httpClient.get(environment.eponaApiUrl + "/alive").pipe(
      map(res => true),
      catchError(res => of(false))
    )
  }

  listenToOnlineStatus(): void {
    setInterval(() => {
      this.httpClient.get(environment.eponaApiUrl + "/alive").subscribe(
        res => {
          if (!this.isOnline.value) {
            this.isOnline.next(true);
          }
        },
        error => {
          if (this.isOnline.value) {
            this.isOnline.next(false);
          }
        }
      );
    },5000);
  }
}
