export class Fields {
  /* ************************************************************************************************************** */
  /* *************************************************** Entrée/Sorties ******************************************* */
  /* ************************************************************************************************************** */
  public static readonly ENTREE_SORTIE_ENTETE = 'idEntreeSortieEntete,' +
    'typeMouvement,' +
    'lieu.idLieu,' +
    'lieuDestination,' +
    'lieuEvenement,' +
    'dateEvenement,' +
    'referenceEvenement,' +
    'codeBordereauLivraison,' +
    'dateBordereauLivraison,' +
    'fraisPort,' +
    'tvaFraisPort.taux,' +
    'temperatureConforme,' +
    'commande.idCommandeEntete,' +
    'commande.externe,' +
    'commande.lieuDemandeur.idLieu,' +
    'commande.numeroEj,' +
    'commande.dateLivraisonSouhaitee,' +
    'commande.fournisseur.idFournisseur,' +
    'commande.fournisseur.nom,' +
    'commande.sousLotZg.idSousLotZg,' +
    'commande.sousLotZg.fraisPort,' +
    'commande.sousLotZg.uniteFrancoPort,' +
    'commande.sousLotZg.montantMaxFraisPort,' +
    'commande.sousLotZg.detailFraisPort,' +
    'commande.fraisPortPrevisionnels,' +
    'entreeSortieEnteteOrigine.idEntreeSortieEntete,' +
    'entreeSortieEnteteOrigine.lieu,' +
    'commentaire,' +
    'dateCreation,utilisateurCreation,' +
    'dateModification,utilisateurModification,' +
    'dateValidation,utilisateurValidation,' +
    'dateEnvoiSf,utilisateurEnvoiSf,' +
    'serviceFaitExiste,' +
    'nbDocuments,' +
    'extra.editable,' +
    'extra.deletable,' +
    'extra.validable,' +
    'extra.correctable';

  public static readonly ENTREE_SORTIE_LIGNES = 'idEntreeSortieLigne,' +
    'dlc,' +
    'quantiteSortie,' +
    'quantiteAttendue,' +
    'quantite,' +
    'ecart,' +
    'ecartDetaille,' +
    'horsMarche,' +
    'referenceFournisseur,' +
    'prixHt,' +
    'montantTotalHt,' +
    'tauxTva,' +
    'prixTtc,' +
    'montantTotalTtc,' +
    'article.idArticle,' +
    'article.articleAchat,' +
    'article.articleVente,' +
    'article.codeArticleAchat,' +
    'article.codeArticleVente,' +
    'article.designationAchat,' +
    'article.designationVente,' +
    'article.tauxTva,' +
    'article.uniteExploitation.abreviation,' +
    'article.articleDlc,' +
    'dateCreation,' +
    'quantiteReceptionnee,' +
    'nbLots,' +
    'quantiteLots';

  public static readonly ENTREE_SORTIE_LIEE = 'idEntreeSortieEntete,lieu.nom';

}
