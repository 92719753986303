import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EntreeSortieEntete} from '../../model/epona-api/EntreeSortieEntete';
import {EntreeSortieLigne} from '../../model/epona-api/EntreeSortieLigne';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {EntreeSortieLignePOST} from '../../model/epona-api/EntreeSortieLignePOST';
import {EntreeSortieLignePUT} from '../../model/epona-api/EntreeSortieLignePUT';
import {EntreeSortieEntetePOST} from "../../model/epona-api/EntreeSortieEntetePOST";
import {EntreeSortieEntetePUT} from "../../model/epona-api/EntreeSortieEntetePUT";
import {HttpService} from "../http.service";
import {EntreeSortieCorrectionPOST} from "../../model/epona-api/EntreeSortieCorrectionPOST";
import {EntreeSortieLigneDetailEcart} from "../../model/epona-api/EntreeSortieLigneDetailEcart";
import {EntreeSortieLigneDetailEcartDTO} from "../../model/epona-api/EntreeSortieLigneDetailEcartDTO";
import {ValidationParamPOST} from "../../model/epona-api/ValidationParamPOST";
import {UserService} from "../user.service";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";
import {ServiceFaitLigne} from "../../model/epona-api/ServiceFaitLigne";
import {ServiceFaitEntetePUT} from "../../model/epona-api/ServiceFaitEntetePUT";
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {EntreeSortieLigneLotPOSTPUT} from "../../model/epona-api/entree-sortie-ligne-lot-post-put";
import {EntreeSortieLigneLot} from "../../model/epona-api/entree-sortie-ligne-lot";
import {EntreeSortieLigneLotSearch} from "../../model/epona-api/entree-sortie-ligne-lot-search";
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";
import {DocumentPOST} from "../../model/epona-api/document-post";
import {DocumentPUT} from "../../model/epona-api/document-put";

@Injectable({
  providedIn: 'root'
})
export class EntreeSortieService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService,
              private userService: UserService) {
    this.baseUrl = this.env.eponaApiUrl + '/entrees-sorties';
  }

  /* ******************* */
  /* Gestion des entêtes */
  /* ******************* */

  getListeEntetes(search: EntreeSortieSearch): Observable<EntreeSortieEntete[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<EntreeSortieEntete[]>(this.baseUrl, {params});
  }

  getEntete(idEntete: number, fields: string): Observable<EntreeSortieEntete> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<EntreeSortieEntete>(this.baseUrl + '/' + idEntete, {params});
  }

  postEntete(entete: EntreeSortieEntete): Observable<EntreeSortieEntete> {
    return this.httpClient.post<EntreeSortieEntete>(this.baseUrl, new EntreeSortieEntetePOST(entete));
  }

  putEntete(entete: EntreeSortieEntete): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + entete.idEntreeSortieEntete, new EntreeSortieEntetePUT(entete));
  }

  patchEntete(entete: EntreeSortieEntete, fraisPort: number|null): Observable<any> {
    return this.httpClient.patch<any>(this.baseUrl + '/' + entete.idEntreeSortieEntete, [{op: 'replace', path: '/fraisPort', value: fraisPort}]);
  }

  postValidation(idEntete: number, validationComplete: boolean): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/validation', new ValidationParamPOST(validationComplete));
  }

  delete(idEntete: number): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/' + idEntete);
  }

  downloadPdf(entete: EntreeSortieEntete): void {
    const url = this.baseUrl + '/' + entete.idEntreeSortieEntete + '/fichiers/pdf';
    const defaultFilename = this.defaultFilenamePdf(entete);
    this.httpService.downloadFile(url, 'application/pdf', defaultFilename);
  }

  defaultFilenamePdf(entete: EntreeSortieEntete): string {
    return `Livraison_${entete.idEntreeSortieEntete}.pdf`;
  }

  /* ******************* */
  /* Gestion des lignes */
  /* ******************* */

  getListeLignes(idEntete: number, fields: string): Observable<EntreeSortieLigne[]> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<EntreeSortieLigne[]>(this.baseUrl + '/' + idEntete + '/lignes', {params});
  }

  deleteLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne): Observable<any> {
    return this.httpClient.delete(this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligne.idEntreeSortieLigne);
  }

  postLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne): Observable<any> {
    ligne.entete = entete;
    return this.httpClient.post<any>(this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes', new EntreeSortieLignePOST(ligne));
  }

  putLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne): Observable<any> {
    ligne.entete = entete;
    return this.httpClient.put<any>(
      this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligne.idEntreeSortieLigne,
      new EntreeSortieLignePUT(ligne));
  }

  postArticlesNonReceptionnes(entete: EntreeSortieEntete): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + entete.idEntreeSortieEntete + '/ajout-articles-non-receptionnes', null,{ observe: 'response' });
  }

  /* ****************** */
  /* Gestion des lots   */
  /* ****************** */

  getListeLots(search: EntreeSortieLigneLotSearch): Observable<EntreeSortieLigneLot[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<EntreeSortieLigneLot[]>(this.baseUrl + '/lots', {params});
  }

  getListeLotsFromLigne(idEntete: number, idLigne: number, fields: string): Observable<EntreeSortieLigneLot[]> {
    const params = this.httpService.buildParams({
      fields: fields
    });
    return this.httpClient.get<EntreeSortieLigneLot[]>(this.baseUrl + '/' + idEntete + '/lignes/' + idLigne + '/lots', {params});
  }

  postLot(idEntete: number, idLigne: number, lot: EntreeSortieLigneLot): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/lignes/' + idLigne + '/lots', new EntreeSortieLigneLotPOSTPUT(lot));
  }

  putLot(idEntete: number, idLigne: number, lot: EntreeSortieLigneLot): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + idEntete + '/lignes/' + idLigne + '/lots/' + lot.idEntreeSortieLigneLot, new EntreeSortieLigneLotPOSTPUT(lot));
  }
  deleteLot(idEntete: number, idLigne: number, lot: EntreeSortieLigneLot): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/' + idEntete + '/lignes/' + idLigne + '/lots/' + lot.idEntreeSortieLigneLot);
  }

  /* ****************** */
  /* Gestion des totaux */
  /* ****************** */

  getTotauxTva(idEntete: number, fields: string): Observable<TotauxTva[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<TotauxTva[]>(this.baseUrl + '/' + idEntete + '/totaux-tva', {params});
  }

  getTotaux(idEntete: number, fields: string): Observable<Totaux> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<Totaux>(this.baseUrl + '/' + idEntete + '/totaux', {params});
  }

  /* *************************** */
  /* Gestion des détails d'écart */
  /* *************************** */

  getDetailsEcartLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne, fields: string): Observable<EntreeSortieLigneDetailEcart[]> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<EntreeSortieLigneDetailEcart[]>(
      this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligne.idEntreeSortieLigne + '/details-ecart',
      {params}
    );
  }

  postDetailsEcartLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne, listeDetails: EntreeSortieLigneDetailEcart[]): Observable<any> {
    return this.httpClient.post<any>(
      this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligne.idEntreeSortieLigne + '/details-ecart',
      EntreeSortieLigneDetailEcartDTO.buildList(listeDetails)
    );
  }

  /* ********************************* */
  /* Gestion des corrections de lignes */
  /* ********************************* */

  getCorrectionsLigne(entete: EntreeSortieEntete, ligne: EntreeSortieLigne, fields: string): Observable<EntreeSortieLigne[]> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<EntreeSortieLigne[]>(
      this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligne.idEntreeSortieLigne + '/corrections',
      {params}
    );
  }

  postCorrectionLigne(entete: EntreeSortieEntete, ligneCorrigee: EntreeSortieLigne, ligneCorrective: EntreeSortieLigne): Observable<any> {
    return this.httpClient.post<any>(
      this.baseUrl + '/' + entete.idEntreeSortieEntete + '/lignes/' + ligneCorrigee.idEntreeSortieLigne + '/corrections',
      new EntreeSortieCorrectionPOST(ligneCorrective)
    );
  }

  /* ******************************* */
  /* Droits de l'utilisateur courant */
  /* ******************************* */

  droitConsultation(params: EntreeSortieParametrage): boolean {
    return this.userService.utilisateurCourant.possedeDroit(params.commonDroitConsultation);
  }
  droitSaisie(params: EntreeSortieParametrage): boolean {
    return this.userService.utilisateurCourant.possedeDroit(params.commonDroitSaisie);
  }
  droitValidation(params: EntreeSortieParametrage): boolean {
    return this.userService.utilisateurCourant.possedeDroit(params.commonDroitValidation);
  }
  droitModification(params: EntreeSortieParametrage): boolean {
    return this.droitSaisie(params) || this.droitValidation(params);
  }

  /* ************************** */
  /* Gestion des services faits */
  /* ************************** */

  getServiceFaitEntete(idEntreeSortie: number, fields: string): Observable<ServiceFaitEntete> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<ServiceFaitEntete>(this.baseUrl + '/' + idEntreeSortie + '/service-fait', {params});
  }

  putServiceFaitEntete(sf: ServiceFaitEntete, livraison: EntreeSortieEntete): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + livraison.idEntreeSortieEntete + '/service-fait', new ServiceFaitEntetePUT(sf));
  }


  getServiceFaitLignes(idEntreeSortie: number, fields: string): Observable<ServiceFaitLigne[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<ServiceFaitLigne[]>(this.baseUrl + '/' + idEntreeSortie + '/service-fait/lignes', {params});
  }


  postEnvoiServiceFait(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/envoi-service-fait', null);
  }

  postSuppressionServiceFait(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/suppression-service-fait', null);
  }

  /* ************************** */
  /*   Gestion des documents    */
  /* ************************** */


  getListeDocuments(idEntete: number, fields: string): Observable<DocumentJoint[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<any>(this.baseUrl + '/' + idEntete + '/documents', {params});
  }

  getDocument(idEntete: number, idDocument: number, fields: string): Observable<DocumentJoint> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<any>(this.baseUrl + '/' + idEntete + '/documents/' + idDocument, {params});
  }

  deleleDocument(idEntete: number, idDocument: number): Observable<any> {
    return this.httpClient.delete<HttpEvent<any>>(this.baseUrl + '/' + idEntete +'/documents/' + idDocument);
  }

  postDocument(idEntete: number, document: DocumentJoint, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(new DocumentPOST(document)));
    return this.httpClient.post<HttpEvent<any>>(this.baseUrl + '/' + idEntete +'/documents', formData);
  }

  putDocument(idEntete: number, document: DocumentJoint, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(new DocumentPUT(document)));
    return this.httpClient.put<HttpEvent<any>>(this.baseUrl + '/' + idEntete +'/documents/' + document.idDocument, formData);
  }

  getFichier(idEntete: number, idDocument: number): Observable<any> {
    const headers = { 'responseType': 'blob' as 'json'};
    return this.httpClient.get<Blob>(this.baseUrl + '/' + idEntete + '/documents/' + idDocument + '/fichier', headers);
  }
}
