import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {BatimentBns} from "../../model/bns-api/batiment-bns";
import {BatimentSearch} from "../../model/bns-api/batiment-search";
import {LieuBns} from "../../model/bns-api/lieu-bns";
import {UtilisateurTests} from "../../model/bns-api/utilisateur-tests";
import {appProperties} from "../../app.properties";
import {LieuBatimentSearch} from "../../model/bns-api/lieu-batiment-search";

@Injectable({
  providedIn: 'root'
})
export class BnsService {

  constructor(private httpClient: HttpClient, private httpService: HttpService) {
  }

  getListeCompleteBatiments(search: BatimentSearch): Observable<BatimentBns[]> {
    const url = environment.bnsApiUrl + '/batiments';
    return this.httpService.getListeComplete<BatimentBns>(url, search);
  }

  getListeCompleteLieuxFromBatiments(idBatiment: number, search: LieuBatimentSearch): Observable<LieuBns[]> {
    const url = environment.bnsApiUrl + '/batiments/' + idBatiment + '/lieux';
    return this.httpService.getListeComplete<LieuBns>(url, search);
  }

  /* ****************** */
  /* Utilisateurs-tests */
  /* ****************** */

  getListeUtilisateursTests(idEtablissement: number): Observable<UtilisateurTests[]> {
    const url = environment.bnsApiUrl + '/utilisateurs-tests';
    const params = this.httpService.buildParams({idEtablissement: idEtablissement, codeApplication: appProperties.codeApplication});
    return this.httpClient.get<UtilisateurTests[]>(url, {params});
  }
}
