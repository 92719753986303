import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Fournisseur} from '../../model/epona-api/Fournisseur';
import {FournisseurSearch} from "../../model/epona-api/FournisseurSearch";
import {MessageTool} from '../../commons/MessageTool';
import {HttpService} from "../http.service";
import { map } from 'rxjs/operators';
import {FournisseurPUT} from "../../model/epona-api/FournisseurPUT";

@Injectable()
export class FournisseurService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/fournisseurs';
  }

  /* ************************ */
  /* Gestion des fournisseurs */
  /* ************************ */

  getListeFournisseursLite(search: FournisseurSearch): Observable<Fournisseur[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<Fournisseur[]>(this.baseUrl + '-lite', {params});
  }

  getListeFournisseurs(search: FournisseurSearch): Observable<Fournisseur[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<Fournisseur[]>(this.baseUrl, {params});
  }

  getFournisseur(idFournisseur: number, fields: string): Observable<Fournisseur> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<Fournisseur>(this.baseUrl + '/' + idFournisseur, {params}).pipe(
      map( resp => {
          return Fournisseur.fromRaw(resp, resp.idFournisseur);
        })
    );
  }

  putFournisseur(fournisseur: Fournisseur): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + fournisseur.idFournisseur, new FournisseurPUT(fournisseur));
  }

  // @Deprecated : utiliser EponaService.getListeMarches
  getListeMarcheFournisseur(): Observable<String[]> {
    let marche: Array<String> = [];
    const params = this.httpService.buildParams({
      idCrous: localStorage.getItem('idCrous'),
      fields: 'codeMarcheOrion'
    });
    return this.httpClient.get<Array<any>>(this.env.eponaApiUrl + '/marches', {params}).pipe(
      map( resp => {
        resp.forEach(a => {
          marche.push(a.codeMarcheOrion);
        });
        return marche;
      })
    );
  }
}
