<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Confirmation de validation</span>
    <span class="space"></span>
    <button type="button" mat-icon-button mat-dialog-close aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="mat-dialog-content-margin">
    <div [innerHTML]="data.body" class="question"></div>

    <div *ngIf="estBLSurCommande()">
      <mat-checkbox [formControl]="validationCompleteCtrl">Commande totalement réceptionnée</mat-checkbox>

      <p class='alert alert-warning' *ngIf="validationCompleteCtrl.value && listeBLNonValides.length === 0 && !validationCompleteForcee">
        <mat-icon>warning</mat-icon>
        Si la commande {{ data.entete.commande.numeroEj }} est totalement réceptionnée, il ne sera plus possible de réceptionner de livraisons la concernant.
      </p>

      <p class="alert alert-warning" *ngIf="validationCompleteCtrl.value && listeBLNonValides.length > 0">
        <mat-icon>warning</mat-icon>
        Si la commande {{ data.entete.commande.numeroEj }} est totalement réceptionnée, les bordereaux de livraison non validés ci-dessous seront supprimés.
      </p>
      <epona-datatable-link-bl label= "Bordereaux de livraison non validés"
                               [listeBL]="listeBLNonValides"
                               [linkFromDialog]="true"
                               [hidden]="!validationCompleteCtrl.value || listeBLNonValides.length === 0"
                               *ngIf="listeBLNonValides?.length > 0"></epona-datatable-link-bl>

    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="confirm()">Confirmer</button>
</mat-dialog-actions>
