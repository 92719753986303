import {CommandeEntete} from "./CommandeEntete";

export class CommandeEntetePATCH {
  codeClientDemandeur: string;
  informationsLivraison: string;
  instructionsCommande: string;

  constructor(commandeEntete: CommandeEntete) {
    this.codeClientDemandeur    = commandeEntete.codeClientDemandeur;
    this.informationsLivraison  = commandeEntete.informationsLivraison;
    this.instructionsCommande   = commandeEntete.instructionsCommande;
  }
}
