<form [formGroup]="lieuForm" class="in-{{use}}">
  <mat-form-field [appearance]="use === 'form' ? 'outline' : 'fill'">
    <mat-label>{{batimentLabel}}</mat-label>

    <mat-select formControlName="idRuLieu"
                (selectionChange)="selectBatiment()"
                [required]="required"
                panelClass="select-panel {{ use === 'filter' ? 'select-lieu-filter-panel' : '' }}">
      <mat-option [value]="DEFAULT">- {{batimentDefault}} -</mat-option>
      <mat-option *ngFor="let batiment of listeBatiments" [value]="batiment.idBatiment">{{batiment.nom}}</mat-option>
    </mat-select>

    <mat-error *ngIf="lieuForm.get('idRuLieu').hasError('required')">{{batimentLabel}} obligatoire</mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="use === 'form' ? 'outline' : 'fill'">
    <mat-label>{{lieuLabel}}</mat-label>

    <mat-select formControlName="idUdLieu"
                (selectionChange)="selectLieu()"
                [required]="required"
                panelClass="select-panel {{ use === 'filter' ? 'select-lieu-filter-panel' : '' }}">
      <mat-option [value]="DEFAULT">- {{lieuDefault}} -</mat-option>
      <mat-option *ngFor="let lieu of listeLieux" [value]="codeLieu(lieu)">{{lieu.nomUd}}</mat-option>
    </mat-select>
    <mat-error *ngIf="lieuForm.get('idUdLieu').hasError('required')">{{lieuLabel}} obligatoire</mat-error>
  </mat-form-field>

  <aide *ngIf="displayHelpTooltip">
    <ng-template [ngIf]="livraisonUniquement" [ngIfElse]="tousLieux">
      Seuls les lieux paramétrés pour accepter les livraisons externes sont proposés.
    </ng-template>
    <ng-template #tousLieux>
      Seuls les lieux paramétrés par une personne habilitée sont proposés.
    </ng-template>
  </aide>
</form>
