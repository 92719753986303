import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, QueryList, Renderer2,
  SimpleChanges, ViewChildren
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ClearMessages, MessageTool} from '../../commons/MessageTool';
import {CommandeLigne} from '../../model/epona-api/CommandeLigne';
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CommandeService} from "../../services/epona/commande.service";
import {Article} from "../../model/epona-api/Article";
import {DialogDataAjoutArticlesCommande} from "../../model/epona-ui/DialogDataAjoutArticlesCommande";
import {concat, Observable, Subject} from "rxjs";
import {DialogAjoutArticlesCommandeComponent} from "../dialog-ajout-articles-commande/dialog-ajout-articles-commande.component";
import {DialogCommentaireCommandeComponent} from "../dialog-commentaire-commande/dialog-commentaire-commande.component";
import {DialogDataCommentaireCommande} from "../../model/epona-ui/DialogDataCommentaireCommande";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {Tools} from "../../commons/Tools";
import {CustomValidators} from "../../commons/CustomValidators";
import {FormTools} from "../../commons/FormTools";
import {DialogDataModificationMultipleArticles} from "../../model/epona-ui/DialogDataModificationMultipleArticles";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {
  DialogModificationMultipleComponent
} from "../../articles/dialog-modification-multiple/dialog-modification-multiple.component";
import {ArticleService} from "../../services/epona/article.service";
import {DesignationArticlePipe} from "../../commons/pipes/designation-article.pipe";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {StockService} from "../../services/epona/stock.service";
import {StockCompactSearch} from "../../model/epona-api/StockCompactSearch";
import {toArray} from "rxjs/operators";

@Component({
  selector: 'epona-commande-lignes',
  templateUrl: './commande-lignes.component.html',
  styleUrls: ['./commande-lignes.component.css']
})

export class CommandeLignesComponent implements OnInit, OnChanges {
  @Input() entete: CommandeEntete;
  @Input() lignes: Array<CommandeLigne>;

  @Output() readonly listeArticlesInserted = new EventEmitter<Array<Article>>();
  @Output() readonly ligneUpdated = new EventEmitter<CommandeLigne>();
  @Output() readonly lignesDeleted = new EventEmitter<Array<CommandeLigne>>();
  @Output() readonly isInvalidFormLignes = new EventEmitter<boolean>();
  @Output() readonly debutModification = new EventEmitter<void>();
  @Output() readonly finModification = new EventEmitter<void>();

  @ViewChildren('quantiteInput') quantiteInputs: QueryList<ElementRef>;
  @ViewChildren('quantiteMagasinInput') quantiteMagasinInputs: QueryList<ElementRef>;

  // DataTable
  dataSource = new MatTableDataSource<CommandeLigne>([]);
  COLUMNS: {[key: string]: TableColumn} = {
    checkBox:             new TableColumn({label: '',            export: true}), // À exporter pour ne pas décaller les entête par rapport aux colonnes
    article:              new TableColumn({label: 'Article'}),
    codeArticle:          new TableColumn({label: 'Code'}),
    designation:          new TableColumn({label: 'Désignation'}),
    reference:            new TableColumn({label: 'Référence',   tooltip: 'Référence fournisseur'}),
    pcb:                  new TableColumn({label: 'Colis.',      tooltip: 'Colisage',              exportFormat: 'decimal'}),
    quantiteStock:        new TableColumn({label: 'Stock',       tooltip: 'Quantité en stock',     exportFormat: 'decimal'}),
    commande:             new TableColumn({label: 'Commande'}),
    quantite:             new TableColumn({label: 'Quantité',                                      exportFormat: 'decimal'}),
    conditionnement:      new TableColumn({label: 'Cdt.',        tooltip: 'Conditionnement'}),
    prixAchatHt:          new TableColumn({label: 'PU HT',       tooltip: 'Prix unitaire HT',      exportFormat: 'decimal'}),
    tauxTva:              new TableColumn({label: 'TVA',         tooltip: 'Taux de TVA'}),
    prixAchatTtc:         new TableColumn({label: 'PU TTC',      tooltip: 'Prix unitaire TTC',     exportFormat: 'decimal'}),
    valeurHt:             new TableColumn({label: 'Valeur HT',                                     exportFormat: 'decimal'}),
    commentaire:          new TableColumn({label: 'Commentaire'}),
    magasin:              new TableColumn({label: 'Magasin'}),
    quantiteMagasin:      new TableColumn({label: 'Quantité',                                      exportFormat: 'decimal'}),
    commentaireMagasin:   new TableColumn({label: 'Commentaire'}),
    reception:            new TableColumn({label: 'Réception'}),
    quantiteReceptionnee: new TableColumn({label: 'Récep.', tooltip: 'Quantité réceptionnée',      exportFormat: 'decimal'}),
  };

  displayedTopHeaders: string[];  // 1re ligne d'entêtes (avec rowspan et colspan)
  displayedHeaders: string[];     // 2e ligne d'entêtes (une par colonne de données sauf checkbox)
  displayedColumns: string[];     // Colonnes affichées
  colspanArticle: number;         // Nombre de colonnes concernant l'article
  colspanCommande: number;        // Nombre de colonnes concernant la commande

  selection = new SelectionModel<CommandeLigne>(true, []);

  form: UntypedFormGroup;
  droitSaisie: boolean;
  droitPreparation: boolean;
  estCataloguePunchout: boolean;
  utilisation: string;
  forceUpdateTotaux: number = 0;

  private enteteLoaded = new Subject<CommandeEntete>();

  constructor(private messageTool: MessageTool,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder,
              private commandeService: CommandeService,
              private articleService: ArticleService,
              private stockService: StockService,
              private designationPipe: DesignationArticlePipe,
              private renderer: Renderer2) {

    this.form = fb.group({
      lignes: fb.array([])
    });
  }

  ngOnInit() {
    this.droitSaisie      = this.commandeService.droitSaisie();
    this.droitPreparation = this.commandeService.droitPreparation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lignes'] && !changes['lignes'].firstChange) {
      this.init();
    }

    if (changes['entete']) {
      if (this.entete) {
        this.enteteLoaded.next(this.entete);
      }

      this.estCataloguePunchout = this.entete
        && this.entete.sousLotZg
        && this.entete.sousLotZg.typeSousLot === 'CAT'
        && this.entete.fournisseur
        && this.entete.fournisseur.possedeSitePunchout;

      this.utilisation = this.estExterne() ? UtilisationArticle.ACHAT : UtilisationArticle.VENTE;

      if (!changes['entete'].firstChange) {
        this.setDisplayedColumns(); // Les colonnes peuvent changer en cas de changement d'état de l'entête
      }
    }

    this.updateDisabledFields();
  }

  private init() {
    if (this.lignes) {
      this.initFormArray();
      this.dataSource.data = this.lignes;
      this.deselectedLignes();

      this.updateQuantitesStock();
      for (const ligne of this.lignes) {
        this.updateParametragesOk(ligne.article);
      }

      // Récupération si le formulaire est valide et émission de l'état au composant parent qui va l'envoyer à l'entête.
      // Nécessaire afin de ne pas pouvoir exécuter une action si le formulaire des lignes est invalide
      this.form.get('lignes').statusChanges.subscribe(() => {
        this.isInvalidFormLignes.emit(!this.form.get('lignes').valid);
      });
    }
  }

  private initFormArray() {
    const formArray = this.fb.array([]);

    for (const ligne of this.lignes) {
      const formGroup = this.fb.group({
        quantite: this.fb.control(
          ligne.quantite,
          [Validators.min(0), CustomValidators.zeroInterdit(true), CommandeLignesComponent.pcb(ligne.article.pcb)]
        ),
        quantiteMagasin: this.fb.control(
          ligne.quantiteMagasin,
          [Validators.min(0)]
        )
      });

      formArray.push(formGroup);
    }

    this.form.setControl('lignes', formArray);
  }

  estBrouillon(): boolean {
    return this.entete && this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.BROUILLON;
  }

  estEnPreparationInterne(): boolean {
    return this.entete
      && this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.EN_PREPARATION
      && this.entete.externe === false;
  }

  estExterne(): boolean {
    return this.entete && this.entete.externe === true;
  }

  estInterne(): boolean {
    return this.entete && this.entete.externe === false;
  }

  private updateQuantitesStock() {
    if (!this.entete) {
      this.enteteLoaded.subscribe({
        next: () => {
          this.updateQuantitesStock();
        }
      })
    } else if (this.estBrouillon()) {
      for (const ligne of this.lignes) {
        this.updateQuantiteStock(ligne);
      }
    }
  }

  private updateQuantiteStock(ligne: CommandeLigne) {
    const search = new StockCompactSearch();
    search.idLieu = this.entete.lieuDemandeur.idLieu;
    search.idArticle = ligne.article.idArticle;
    search.fields = 'quantite';
    this.stockService.getListeStocksCompacts(search).subscribe({
      next: value => (ligne.quantiteStock = value.length > 0 ? value[0].quantite : 0)
    });
  }

  private estEnReceptionOuReceptionnee(): boolean {
    return this.entete
      && (this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.EN_RECEPTION
       || this.entete.etatCommande.codeEtatCommande === CodeEtatCommande.RECEPTIONNEE)
  }

  private updateParametragesOk(article: Article) {
    article.parametrageFamilleMarchesOk = !this.estExterne()
      || article.origineAchat === 'BNA'
      || article.familleMarches != null
    ;
    article.parametrageCompteComptableOk = !this.estExterne()
      || article.origineAchat === 'BNA'
      || article.sousGroupe != null
    ;
    article.parametragesNationauxOk = article.parametrageFamilleMarchesOk && article.parametrageCompteComptableOk;
  }

  updateDisabledFields() {
    const formArray = this.form.get('lignes') as UntypedFormArray;

    for (let formGroup of formArray.controls) {
      if (this.estBrouillon() && this.droitSaisie) {
        formGroup.get('quantite').enable();
      } else {
        formGroup.get('quantite').disable();
      }

      if (this.estEnPreparationInterne() && this.droitPreparation) {
        formGroup.get('quantiteMagasin').enable();
      } else {
        formGroup.get('quantiteMagasin').disable();
      }
    }
  }

  /* ****************** */
  /* Colonnes affichées */
  /* ****************** */

  private setDisplayedColumns() {
    this.displayedTopHeaders = ['checkBox', 'article', 'commande', 'magasin', 'reception'];

    this.displayedColumns  = [
      'checkBox',

      'codeArticle',                // article.codeArticleVente ou article.codeArticleAchat
      'designation',
      'reference',
      'pcb',                        // article.pcb ou colisage
      'quantiteStock',

      'quantite',
      'conditionnement',
      'prixAchatHt',
      'tauxTva',
      'prixAchatTtc',
      'valeurHt',
      'commentaire',

      'quantiteMagasin',
      'commentaireMagasin',

      'quantiteReceptionnee',
    ];

    this.colspanArticle = 5;
    this.colspanCommande = 7;

    if (!this.droitSaisie) {
      this.removeTopHeader('checkBox');
      this.removeColumn('checkBox');
    }

    // Les colonnes du magasin ne sont pas affichées pour les commandes externes ou à l'état brouillon
    if (this.estExterne() || this.estBrouillon()) {
      this.removeTopHeader('magasin');
      this.removeColumn('quantiteMagasin');
      this.removeColumn('commentaireMagasin');
    }

    // La colonne "Colisage" n'est affichée qu'en brouillon et en préparation interne
    if (!this.estBrouillon() && !this.estEnPreparationInterne()) {
      this.removeColumn('pcb');
      this.colspanArticle--;
    }

    // La colonne 'Stock' n'est affichée qu'en brouillon
    if (!this.estBrouillon()) {
      this.removeColumn('quantiteStock');
      this.colspanArticle--;
    }

    // Les colonnes liées aux marchés ne sont pas affichées pour les commandes internes
    if (this.estInterne()) {
      this.removeColumn('reference');
      this.colspanArticle--;

      this.removeColumn('conditionnement');
      this.removeColumn('prixAchatHt');
      this.removeColumn('tauxTva');
      this.removeColumn('prixAchatTtc');
      this.removeColumn('valeurHt');
      this.colspanCommande -= 5
    }


    if (!this.estEnReceptionOuReceptionnee() || !this.commandeService.droitConsultationLivraison()) {
      this.removeTopHeader('reception');
      this.removeColumn('quantiteReceptionnee');
    }

    // Les entêtes sont identiques aux colonnes affichées sans la colonne 'checkBox' (le topHeader a un rowspan de 2)
    this.displayedHeaders = Object.assign([], this.displayedColumns);
    this.removeHeader('checkBox');
  }

  private removeTopHeader(columnName: string) {
    Tools.removeItemFromStringArray(columnName, this.displayedTopHeaders);
  }
  private removeHeader(columnName: string) {
    Tools.removeItemFromStringArray(columnName, this.displayedHeaders);
  }
  private removeColumn(columnName: string) {
    delete this.COLUMNS[columnName];
    Tools.removeItemFromStringArray(columnName, this.displayedColumns);
  }

  /* ******************** */
  /* Sélection des lignes */
  /* ******************** */

  deselectedLignes() {
    // si pas de lignes sélectionné, on désélectionne tout
    if (!this.lignes.some(inventaireLigne => inventaireLigne.selected === true)) {
      this.selection.clear();
    }
  }

  // Si le nombre d'éléments sélectionnés correspond au nombre total de lignes
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Sélectionne toutes les lignes si elles ne sont pas sélectionnés sinon déselectionne toutes les lignes
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /* ********************* */
  /* Suppression de lignes */
  /* ********************* */

  openDialogSuppression(): void {
    if (this.selection.selected.length === 0 ) {
      return this.messageTool.sendErrorMessage("Vous devez sélectionner au moins un article");
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: this.selection.selected.length > 1
        ? "Êtes-vous sûr de vouloir supprimer les articles sélectionnés de cette commande ?"
        : "Êtes-vous sûr de vouloir supprimer l'article sélectionné de cette commande ?"};

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let listeObservables: Array<Observable<any>> = [];

        for (let ligne of this.selection.selected) {
          listeObservables.push(this.commandeService.deleteLigne(this.entete.idCommandeEntete, ligne.idCommandeLigne));
        }

        concat(...listeObservables).pipe(
          toArray() // afin de n'avoir qu'un seul next en résultat du subscribe
        ).subscribe({
          next: res => {
            if (listeObservables.length > 1) {
              this.messageTool.sendSuccess("Les articles ont été supprimés avec succès de la commande", ClearMessages.TRUE);
            } else {
              this.messageTool.sendSuccess("L'article a été supprimé avec succès de la commande", ClearMessages.TRUE);
            }

            this.forceUpdateTotaux++;

            // Envoi de la liste des lignes supprimées au composant parent
            this.lignesDeleted.emit(this.selection.selected);
          },
          error: err => {
            this.messageTool.sendError(err);
          }
        });
      }
    });
  }

  /* *************** */
  /* Ajout de lignes */
  /* *************** */

  openDialogAjoutArticles(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = new DialogDataAjoutArticlesCommande();
    dialogConfig.data.entete = this.entete;
    dialogConfig.data.lignes = this.lignes;
    dialogConfig.data.utilisation = this.utilisation;

    const dialogRef = this.dialog.open(DialogAjoutArticlesCommandeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if (dialogConfig.data.listeArticles) {
        this.listeArticlesInserted.emit(dialogConfig.data.listeArticles);
      }
    });
  }

  openDialogCommentaireCommande(ligne: CommandeLigne, field: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.data = new DialogDataCommentaireCommande();
    dialogConfig.data.entete = this.entete;
    dialogConfig.data.ligne = ligne;
    dialogConfig.data.utilisation = this.utilisation;
    dialogConfig.data.field = field;

    const dialogRef = this.dialog.open(DialogCommentaireCommandeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if (dialogConfig.data.ligneUpdated) {
        FormTools.markAsSuccess(ligne);
        this.ligneUpdated.emit(dialogConfig.data.ligneUpdated);
      }
    });
  }

  openDialogCommentaire(ligne: CommandeLigne) {
    this.openDialogCommentaireCommande(ligne, 'commentaire');
  }

  openDialogCommentaireMagasin(ligne: CommandeLigne) {
    this.openDialogCommentaireCommande(ligne, 'commentaireMagasin');
  }

  /* ********************* */
  /* Modification de ligne */
  /* ********************* */

  debutUpdate(index: number, formControlName: string) {
    this.debutModification.emit();

    const formCtrl = this.getLigneCtrl(index).get(formControlName);
    // En cas d'erreur liée au webservice REST, celle-ci n'est plus mentionnée
    if (formCtrl.hasError('network') || formCtrl.hasError('5xx') || formCtrl.hasError('4xx')) {
      formCtrl.setErrors(null);
    }
  }

  finUpdate() {
    this.finModification.emit();
  }

  updateLigne(index: number, formControlName: string, goToNextCtrl: boolean = false) {
    const ligne: CommandeLigne = this.lignes[index];
    const ligneCtrl = this.getLigneCtrl(index);

    if (!ligneCtrl.valid) {
      const designation = this.designationPipe.transform(ligne.article, this.utilisation);
      this.messageTool.sendErrorMessage(`La ligne de l'article ${designation} est erronée`);
      FormTools.markAsFailure(ligne);
      this.finUpdate();

    } else if (ligneCtrl.dirty) {
      ligne.quantite        = ligneCtrl.get('quantite').value;
      ligne.quantiteMagasin = ligneCtrl.get('quantiteMagasin').value;
      ligne.valeurHt        = ligne.quantite && ligne.prixAchatHt ? ligne.quantite * ligne.prixAchatHt : null;

      this.commandeService.putLigne(this.entete.idCommandeEntete, ligne).subscribe(() => {
        const designation = this.designationPipe.transform(ligne.article, this.utilisation);
        const message = `La ligne de l'article ${designation} a été mise à jour avec succès`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);

        // Les valeurs effectivement sauvegardées sont settées dans le formulaire
        ligneCtrl.get('quantite').setValue(ligne.quantite);
        ligneCtrl.get('quantiteMagasin').setValue(ligne.quantiteMagasin);

        // Ligne  marquée comme n'ayant pas été touchée
        ligneCtrl.markAsPristine();

        this.forceUpdateTotaux++;

        // Envoi au composant parent qu'une ligne a été modifiée
        this.ligneUpdated.emit(ligne);

        FormTools.markAsSuccess(ligne);

        if (goToNextCtrl) {
          this.goToNextCtrl(index, formControlName);
        }

      }, err => {
        ligneCtrl.get(formControlName).setErrors(FormTools.ngErrorFromHttpError(err));
        this.messageTool.sendError(err);
        FormTools.markAsFailure(ligne);

      }).add(() => {
        this.finUpdate();
      });

    } else {
      FormTools.unmark(ligne);
      this.finUpdate();
      if (goToNextCtrl) {
        this.goToNextCtrl(index, formControlName);
      }
    }
  }

  private getLigneCtrl(index: number): UntypedFormControl {
    return (this.form.get('lignes') as UntypedFormArray).controls[index] as UntypedFormControl;
  }

  private goToNextCtrl(index: number, formControlName: string): void {
    let inputs: QueryList<ElementRef>;
    switch (formControlName) {
      case 'quantite':        inputs = this.quantiteInputs;        break;
      case 'quantiteMagasin': inputs = this.quantiteMagasinInputs; break;
      default: return;
    }

    const nextIndex = index + 1;
    if (inputs.length > 0 && inputs.get(nextIndex) !== undefined) {
      this.renderer.selectRootElement(inputs.get(nextIndex).nativeElement).focus();
    }
  }

  // Test si un control du FormArray des lignes a une erreur
  hasError(index: number, controlName: string, errorCode: string): boolean {
    return this.getLigneCtrl(index).get(controlName).hasError(errorCode);
  }

  // Validateur personnalisé
  private static pcb = (pcb: number) => {
    return (formControl: UntypedFormControl) => {
      if (formControl.value % pcb > 0) {
        return {'pcb': true};
      } else {
        return  null;
      }
    }
  };

  openDialogArticle(ligne: CommandeLigne): void {
    this.articleService.getArticle(ligne.article.idArticle, null).subscribe(article => {
      let dialogConfig = new MatDialogConfig<DialogDataModificationMultipleArticles>();
      dialogConfig.data = new DialogDataModificationMultipleArticles();
      dialogConfig.data.listeArticles = [article];
      dialogConfig.data.utilisation = this.estExterne() ? UtilisationArticle.ACHAT : UtilisationArticle.VENTE;
      dialogConfig.minWidth = '500px';

      const dialogRef = this.dialog.open(DialogModificationMultipleComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          ligne.article = article;
          this.updateParametragesOk(ligne.article);
          this.ligneUpdated.emit(ligne);
        }
      });
    }, err => {
      this.messageTool.sendError(err);
    });
  }
}
