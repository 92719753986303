import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {ReferentielOrionSearch} from "../../model/epona-api/ReferentielOrionSearch";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {ReferentielOrion} from "../../model/epona-api/ReferentielOrion";

@Injectable()
export class OrionService {
  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/orion';
  }

  getListeReferentielOrion(type: TypeReferentielOrion, search: ReferentielOrionSearch): Observable<ReferentielOrion[]> {
    const params = this.httpService.buildParams(search);
    const url = this.baseUrl + '/' + type.urlPart;
    return this.httpClient.get<ReferentielOrion[]>(url, {params});
  }
}
