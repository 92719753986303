/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import {inject} from '@angular/core';
import {Subject} from 'rxjs';
import {CanDeactivateComponent} from "./can-deactivate.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {ConfirmDialogData} from "../../commons/dialog-confirm/confirm-dialog-data";
import {CanDeactivateFn} from "@angular/router";

export const canDeactivateGuard: CanDeactivateFn<CanDeactivateComponent> = (component: CanDeactivateComponent) => {
  const dialog = inject(MatDialog);

  if (!component.canDeactivate()) {
    const subject = new Subject<boolean>();

    let dialogConfig = new MatDialogConfig<ConfirmDialogData>();
    dialogConfig.data = new ConfirmDialogData();
    dialogConfig.data.title = 'Confirmation';
    dialogConfig.data.body = "<p>Confirmez-vous vouloir quitter la page&nbsp;?</p><p>Les modifications que vous avez apportées ne seront pas enregistrées.</p>";
    dialogConfig.data.validationWithObservable = true;

    const dialogRef = dialog.open(DialogConfirmComponent, dialogConfig);
    dialogRef.componentInstance.subject = subject;

    return subject.asObservable();

  } else {
    return true;
  }
};
