import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {UserService} from '../services/user.service';
import {TopbarComponent} from "../topbar/topbar.component";

export const canActivateRouteGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);

  // Activation du menu et sous-menu dans la barre d'outils principale
  TopbarComponent.setActivatedMenu(route);

    // Droit nécessaire pour pouvoir accéder à la route
    let droitNecessaire = route.data["droit"] as string;

  return userService.isAuth(droitNecessaire);
};
