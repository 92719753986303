<div *ngIf="lignes !== undefined" [ngClass]="{'editable': isEditable()}">
  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogAjoutLigne()"
            [disabled]="!isEditable()"
            *ngIf="params.editActionButtonsDisplayed && !modeConsultation && droitSaisie">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogSuppression()"
            [disabled]="!isEditable()"
            *ngIf="params.editActionButtonsDisplayed && !modeConsultation && droitSaisie">
      <fa-icon icon="trash"></fa-icon> Supprimer…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="exporter.exportTable({nomFichier: params.editExportFileName + '-' + entete.idEntreeSortieEntete + '.xlsx'})"
            [disabled]="dataSource.data.length === 0">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>

    <form-displayed-columns [availableColumns]="COLUMNS"
                            [storeCode]="COLUMNS_STORE_CODE"
                            [(displayedColumns)]="displayedColumns"
                            (displayedColumnsChange)="onDisplayedColumnChanged()"></form-displayed-columns>
  </div>

  <form [formGroup]="form" autocomplete="off">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows="true"
           formArrayName="lignes"
           cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
           class="mat-elevation-z8"
           aria-label="Liste des lignes d'une entrée/sortie">

      <ng-container matColumnDef="checkBox">
        <th mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['checkBox'].tooltip">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [disabled]="!isEditable()"
                        *ngIf="params.editActionButtonsDisplayed">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(data) : null"
                        [checked]="selection.isSelected(data)"
                        [disabled]="!isEditable()"
                        *ngIf="params.editActionButtonsDisplayed">
          </mat-checkbox>

          <div class="update-result">
            <span class="success"><fa-icon icon="check-circle"></fa-icon></span>
            <span class="failure"><fa-icon icon="times-circle"></fa-icon></span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="codeArticle">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['codeArticle'].tooltip">{{ COLUMNS['codeArticle'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.article | codeArticle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="referenceFournisseur">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['referenceFournisseur'].tooltip">{{ COLUMNS['referenceFournisseur'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.referenceFournisseur }}
        </td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['designation'].tooltip">{{ COLUMNS['designation'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.article | designation }} </td>
      </ng-container>

      <ng-container matColumnDef="quantiteEnStock">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteEnStock'].tooltip">{{ COLUMNS['quantiteEnStock'].label }}</th>
        <td mat-cell *matCellDef="let data">
          {{ data.quantiteEnStock | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dlc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['dlc'].tooltip">{{ COLUMNS['dlc'].label }}</th>
        <td mat-cell *matCellDef="let data">{{data.dlc | dateFormat}}</td>
      </ng-container>

      <ng-container matColumnDef="quantiteSortie">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteSortie'].tooltip">{{ COLUMNS['quantiteSortie'].label }}</th>
        <td mat-cell *matCellDef="let data"> {{ data.quantiteSortie | decimal4 }} </td>
      </ng-container>

      <ng-container matColumnDef="quantiteCommandee">
        <th class="title-container quantite-commandee" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteCommandee'].tooltip">
          <button type="button"
                  mat-button
                  (click)="openDialogConfirmationCopie()"
                  matTooltip="Copier les quantités commandées dans les quantités livrées…"
                  *ngIf="isEditable()">{{ COLUMNS['quantiteCommandee'].label }}</button>

          <span *ngIf="!isEditable()">{{ COLUMNS['quantiteCommandee'].label }}</span>
        </th>
        <td mat-cell *matCellDef="let data;let i = dataIndex" class="quantite quantite-commandee">
          <button type="button"
                  mat-button
                  (click)="copieCommandeeDansReceptionnee(data, i)"
                  *ngIf="isEditable()">{{ data.quantiteCommandee | decimal4 }}</button>

          <span *ngIf="!isEditable()">{{ data.quantiteCommandee | decimal4 }}</span>
          <div style="color: red; font-size: 0.9em" *ngIf="data.horsMarche">(hors lot-marché)</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantiteReceptionnee">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteReceptionnee'].tooltip">{{ COLUMNS['quantiteReceptionnee'].label }}</th>
        <td mat-cell *matCellDef="let data">
          {{ data.quantiteReceptionnee | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantiteAReceptionner">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteAReceptionner'].tooltip">{{ COLUMNS['quantiteAReceptionner'].label }}</th>
        <td mat-cell *matCellDef="let data">
          {{ (data.quantiteCommandee - data.quantiteReceptionnee) | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantiteAttendue">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteAttendue'].tooltip">{{ COLUMNS['quantiteAttendue'].label }}</th>
        <td mat-cell *matCellDef="let data"> {{ data.quantiteAttendue | decimal4 }} </td>
      </ng-container>

      <ng-container matColumnDef="quantite">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantite'].tooltip">{{ COLUMNS['quantite'].label }}</th>
        <td mat-cell *matCellDef="let data; let index = dataIndex" [formGroupName]="index">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <input matInput
                   #quantiteInput
                   type="number" [min]="0"
                   (keydown)="debutUpdate(index, 'quantite')"
                   (keyup)="updateMontants(index)"
                   (blur)="updateLigne(index, 'quantite')"
                   (keyup.enter)="updateLigne(index, 'quantite', true)"
                   formControlName="quantite">
            <mat-error *ngIf="hasError(index, 'quantite', 'min')">Qtté. négative interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'zeroInterdit')">Qtté. nulle interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'nbMaxDecimals')">Maximum {{ NB_MAX_DECIMALES_QUANTITE }} décimales</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'quantiteTropPetite')">Qtté. min&nbsp;: {{ data.quantiteLots }} (lots)</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'network')">Erreur réseau</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', '5xx')">Erreur serveur</mat-error>
          </mat-form-field>

          <!-- Version texte uniquement pour l'export -->
          <div class="cdk-visually-hidden">{{ calculValeurAPI(data.quantite) | decimal4 }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="uniteExploitation">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['uniteExploitation'].tooltip">{{ COLUMNS['uniteExploitation'].label }}</th>
        <td mat-cell *matCellDef="let data"> {{ data.article.uniteExploitation?.abreviation }} </td>
      </ng-container>

      <ng-container matColumnDef="prixHt">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['prixHt'].tooltip">{{ COLUMNS['prixHt'].label }}</th>

        <td mat-cell *matCellDef="let data; let index = dataIndex" [formGroupName]="index">
          <div *ngIf="params.editPrixSaisis">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <input matInput
                     #prixHtInput
                     type="number" [min]="0"
                     (keydown)="debutUpdate(index, 'prixHt')"
                     (keyup)="updateMontants(index)"
                     (blur)="updateLigne(index, 'prixHt')"
                     (keyup.enter)="updateLigne(index, 'prixHt', true)"
                     formControlName="prixHt">
              <mat-error *ngIf="hasError(index, 'prixHt', 'min')">Prix négatif interdit</mat-error>
              <mat-error *ngIf="hasError(index, 'prixHt', 'ttc_inferieur_ht')">TTC inférieur au HT</mat-error>
              <mat-error *ngIf="hasError(index, 'prixHt', 'network')">Erreur réseau</mat-error>
              <mat-error *ngIf="hasError(index, 'prixHt', '5xx')">Erreur serveur</mat-error>
            </mat-form-field>

            <!-- Version texte uniquement pour l'export -->
            <div class="cdk-visually-hidden">{{ data.prixHt | decimal4 }}</div>
          </div>

          <div *ngIf="!params.editPrixSaisis">
            {{ data.prixHt | decimal4 }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="montantHt">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['montantHt'].tooltip">{{ COLUMNS['montantHt'].label }}</th>

        <td mat-cell *matCellDef="let data; let index = dataIndex" [formGroupName]="index">
          {{ data.montantTotalHt | decimal2 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tauxTva">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['tauxTva'].tooltip">{{ COLUMNS['tauxTva'].label }}</th>

        <td mat-cell *matCellDef="let data; let index = dataIndex">
          {{ (data.tauxTva ? data.tauxTva : data.article.tauxTva) | decimal1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixTtc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['prixTtc'].tooltip">{{ COLUMNS['prixTtc'].label }}</th>

        <td mat-cell *matCellDef="let data; let index = dataIndex" [formGroupName]="index">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <input matInput
                   #prixTtcInput
                   type="number" [min]="0"
                   (keydown)="debutUpdate(index, 'prixTtc')"
                   (keyup)="updateMontants(index)"
                   (blur)="updateLigne(index, 'prixTtc')"
                   (keyup.enter)="updateLigne(index, 'prixTtc', true)"
                   formControlName="prixTtc">
            <mat-error *ngIf="hasError(index, 'prixTtc', 'min')">Prix négatif interdit</mat-error>
            <mat-error *ngIf="hasError(index, 'prixTtc', 'ttc_inferieur_ht')">TTC inférieur au HT</mat-error>
            <mat-error *ngIf="hasError(index, 'prixTtc', 'network')">Erreur réseau</mat-error>
            <mat-error *ngIf="hasError(index, 'prixTtc', '5xx')">Erreur serveur</mat-error>
          </mat-form-field>

          <!-- Version texte uniquement pour l'export -->
          <div class="cdk-visually-hidden">{{ data.prixTtc | decimal4 }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="montantTtc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['montantTtc'].tooltip">{{ COLUMNS['montantTtc'].label }}</th>

        <td mat-cell *matCellDef="let data; let index = dataIndex" [formGroupName]="index">
          {{ data.montantTotalTtc | decimal2 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ecart">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['ecart'].tooltip">{{ COLUMNS['ecart'].label }}</th>
        <td mat-cell *matCellDef="let data">
          {{ data.ecart > 0 ? '+' + data.ecart : data.ecart }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ecartDetaille">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['ecartDetaille'].tooltip">{{ COLUMNS['ecartDetaille'].label }}</th>
        <td mat-cell *matCellDef="let data"
            [ngClass]="{'erreur-detail': !ecartDetailleOK(data)}">
          <button type="button"
                  mat-button
                  class="detail"
                  (click)="openDialogDetailEcart(data)"
                  [disabled]="data.success === false || modeConsultation"
                  matTooltip="Détailler l'écart de cette ligne">
            {{ ecartDetaille(data) > 0 ? '+' + ecartDetaille(data) : ecartDetaille(data) }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="corrections">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col"
            [matTooltip]="COLUMNS['corrections'].tooltip">{{ COLUMNS['corrections'].label }}</th>
        <td mat-cell *matCellDef="let data">
          <button type="button"
                  mat-button
                  (click)="openDialogCorrection(data)"
                  matTooltip="Corriger cette ligne"
                  *ngIf="droitSaisie">
            <fa-icon icon="edit"></fa-icon>
          </button>

          <button type="button"
                  mat-button
                  (click)="openDialogHistoriqueCorrections(data)"
                  matTooltip="Historique des corrections de cette ligne"
                  [matTooltipDisabled]="!data.dateCreation"
                  [disabled]="!data.dateCreation">
            <fa-icon icon="list-alt"></fa-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="gestionLots">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['gestionLots'].tooltip">{{COLUMNS['gestionLots'].label}}</th>
        <td mat-cell *matCellDef="let data">
          <button type="button"
                  mat-button
                  (click)="openDialogGestionLots(data)"
                  [disabled]="data.success === false || modeConsultation"
                  matTooltip="Gestion des lots"
                  *ngIf="isEditable() || data.nbLots > 0">
            <fa-icon icon="th-large"></fa-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="fdnc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['fdnc'].tooltip">{{COLUMNS['fdnc'].label}}</th>
        <td mat-cell *matCellDef="let data">
          <button type="button"
                  mat-button
                  (click)="openDialogCreationFdnc(data)"
                  [disabled]="!droitSaisieFdnc"
                  matTooltip="Saisir une FDNC sur cet article">
            <fa-icon icon="plus-circle"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'success': row.success === true, 'failure': row.success === false }"></tr>


      <!-- Ligne de frais de port -->
      <ng-container matColumnDef="fillerFraisPort">
        <td mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="libelleFraisPort">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="nbColonnesLibelleFraisPort" class="libelle-frais-port">
          <label for="frais-port">Frais de port&nbsp;:</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="montantHtFraisPort">
        <td mat-footer-cell *matFooterCellDef>
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <input matInput id="frais-port"
                   type="number" [min]="0" [max]="entete.commande?.sousLotZg.montantMaxFraisPort"
                   step="0.01"
                   (keydown)="debutUpdateFraisPort()"
                   (blur)="updateFraisPort()"
                   (keyup.enter)="updateFraisPort()"
                   [formControl]="formCtrlFraisPort">
            <mat-hint *ngIf="entete.commande?.fraisPortPrevisionnels !== undefined">Prév.&nbsp;: {{ entete.commande?.fraisPortPrevisionnels | decimal2}}</mat-hint>
            <mat-error *ngIf="formCtrlFraisPort.hasError('min')">Montant négatif interdit</mat-error>
            <mat-error *ngIf="formCtrlFraisPort.hasError('max')">Montant maximum&nbsp;: {{ entete.commande.sousLotZg.montantMaxFraisPort | decimal2 }}&nbsp;€</mat-error>
            <mat-error *ngIf="formCtrlFraisPort.hasError('nbMaxDecimals')">Max. 2 décimales</mat-error>
            <mat-error *ngIf="formCtrlFraisPort.hasError('network')">Erreur réseau</mat-error>
            <mat-error *ngIf="formCtrlFraisPort.hasError('5xx')">Erreur serveur</mat-error>
          </mat-form-field>

          <!-- Version texte uniquement pour l'export -->
          <div class="cdk-visually-hidden">{{ entete.fraisPort | decimal2 }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tauxTvaFraisPort">
        <td mat-footer-cell *matFooterCellDef>
          {{ tauxTvaFraisPort | decimal1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="montantTtcFraisPort">
        <td mat-footer-cell *matFooterCellDef>
          {{ (entete.fraisPort >= 0 ? (entete.fraisPort * (100 + tauxTvaFraisPort) / 100) : null) | decimal2 }}
        </td>
      </ng-container>

      <tr mat-footer-row *matFooterRowDef="displayedColumnsFraisPort" class="frais-port" [hidden]="dataSource.data.length === 0 || !afficherLigneFraisPort"></tr>


      <!-- Ligne de pied de tableau en cas de liste vide -->
      <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns ? displayedColumns.length : 1">Liste vide</td>
      </ng-container>

      <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
    </table>
  </form>

  <epona-entree-sortie-totaux [entete]="entete" [forceUpdate]="forceUpdateTotaux" *ngIf="estBLSurCommandeExterne()"></epona-entree-sortie-totaux>
</div>



