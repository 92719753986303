import {CommandeEntete} from "./CommandeEntete";

export class CommandeCourriel {
  commande: CommandeEntete;
  interne: boolean;

  mailDemandeur: string;
  telephoneContactLivraison: string;
  mailsDemandeursSupp: string;
  mailFournisseur: string;
  sujet: string;
  message: string;
  dateEnvoi: Date;
}
