import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList, Renderer2,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {Action, DialogDataLigneEntreeSortie} from "../../model/epona-ui/DialogDataLigneEntreeSortie";
import {DialogAjoutLigneComponent} from "../dialog-ajout-ligne/dialog-ajout-ligne.component";
import {
  AbstractControl, FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors,
  Validators
} from "@angular/forms";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {forkJoin, Observable, Subject} from "rxjs";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {Tools} from "../../commons/Tools";
import {DialogDataHistoriqueCorrectionsEntreeSortie} from "../../model/epona-ui/DialogDataHistoriqueCorrectionsEntreeSortie";
import {DialogHistoriqueCorrectionsComponent} from "../dialog-historique-corrections/dialog-historique-corrections.component";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {CommandeService} from "../../services/epona/commande.service";
import {CustomValidators} from "../../commons/CustomValidators";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {FormTools} from "../../commons/FormTools";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {StockCompactSearch} from "../../model/epona-api/StockCompactSearch";
import {StockService} from "../../services/epona/stock.service";
import {DesignationArticlePipe} from "../../commons/pipes/designation-article.pipe";
import {DialogGestionLotsComponent} from "../dialog-gestion-lots/dialog-gestion-lots.component";
import {DialogDataLigneLotEntreeSortie} from "../../model/epona-ui/dialog-data-ligne-lot-entree-sortie";
import {CodeDroit} from '../../commons/constants/CodeDroit';
import {UserService} from '../../services/user.service';
import {DialogDataCreationFdnc} from '../../fdnc/dialog-creation-fdnc/dialog-data-creation-fdnc';
import {DialogCreationFdncComponent} from '../../fdnc/dialog-creation-fdnc/dialog-creation-fdnc.component';
import {CrousService} from "../../services/epona/crous.service";

@Component({
  selector: 'epona-entree-sortie-lignes',
  templateUrl: './entree-sortie-lignes.component.html',
  styleUrls: ['./entree-sortie-lignes.component.css']
})
export class EntreeSortieLignesComponent implements OnInit, OnChanges {

  private static readonly NB_MAX_DECIMALES_QUANTITE = 4;
  private static readonly NB_MAX_DECIMALES_PRIX = 4;

  private readonly FIELDS_DETAILS_ECART = 'type,quantite,commentaire';

  dataSource = new MatTableDataSource<EntreeSortieLigne>([]);
  selection = new SelectionModel<EntreeSortieLigne>(true, []);

  @Input() readonly params: EntreeSortieParametrage;
  @Input() entete: EntreeSortieEntete;
  @Input() lignes: Array<EntreeSortieLigne>;
  @Input() modeConsultation: boolean = false;

  @Output() readonly ligneInserted = new EventEmitter<EntreeSortieLigne>();
  @Output() readonly lignesInserted = new EventEmitter<boolean>();
  @Output() readonly ligneUpdated  = new EventEmitter<EntreeSortieLigne>();
  @Output() readonly lignesDeleted = new EventEmitter<Array<EntreeSortieLigne>>();
  @Output() readonly debutModification = new EventEmitter<void>();
  @Output() readonly finModification = new EventEmitter<void>();

  @ViewChildren('quantiteInput') quantiteInputs: QueryList<ElementRef>;
  @ViewChildren('prixHtInput') prixHtInputs: QueryList<ElementRef>;
  @ViewChildren('prixTtcInput') prixTtcInputs: QueryList<ElementRef>;

  form: UntypedFormGroup;
  droitSaisie: boolean;
  droitSaisieFdnc: boolean;

  private enteteLoaded = new Subject<EntreeSortieEntete>();

  COLUMNS: {[key: string]: TableColumn} = {};
  COLUMNS_STORE_CODE = null;

  displayedColumns: string[] = [];

  displayedColumnsFraisPort: string[] = [];
  afficherLigneFraisPort: boolean = false;
  nbColonnesLibelleFraisPort: number = 0;
  formCtrlFraisPort: FormControl;
  tauxTvaFraisPort: number|null = null;
  forceUpdateTotaux: number = 0;

  readonly NB_MAX_DECIMALES_QUANTITE = EntreeSortieLignesComponent.NB_MAX_DECIMALES_QUANTITE;
  readonly NB_MAX_DECIMALES_PRIX     = EntreeSortieLignesComponent.NB_MAX_DECIMALES_PRIX;

  constructor(private entreeSortieService: EntreeSortieService,
              private commandeService: CommandeService,
              private stockService: StockService,
              private crousService: CrousService,
              private userService: UserService,
              private messageTool: MessageTool,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private designationPipe: DesignationArticlePipe,
              private renderer: Renderer2) {
    this.form = fb.group({
      lignes: fb.array([])
    });
    this.formCtrlFraisPort = fb.control(null, [Validators.min(0), CustomValidators.nbMaxDecimals(2)]);
  }

  ngOnInit(): void {
    this.droitSaisie = this.entreeSortieService.droitSaisie(this.params);
    this.droitSaisieFdnc = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lignes'] && !changes['lignes'].firstChange) {
      this.init();
      this.loadQuantitesCommandees();
      this.loadQuantitesEnStock();
    }

    if (changes['entete'] && !changes['entete'].firstChange) {
      this.enteteLoaded.next(this.entete);

      // Les colonnes peuvent changer en cas de validation de l'entête
      this.initColumnsData();
      this.setDisplayedColumns();

      this.formCtrlFraisPort.setValue(this.entete.fraisPort);
      this.tauxTvaFraisPort = this.entete.tvaFraisPort ? this.entete.tvaFraisPort.taux : null;

      this.updateDisabledFields();
    }
  }


  openDialogCreationFdnc(ligne: EntreeSortieLigne): void {
    let dialogConfig = new MatDialogConfig<DialogDataCreationFdnc>();
    dialogConfig.data = new DialogDataCreationFdnc();
    dialogConfig.data.commande  = this.entete.commande;
    dialogConfig.data.livraison = this.entete;
    dialogConfig.data.ligne = ligne;
    dialogConfig.minWidth = '850px';
    dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'étape
    dialogConfig.disableClose = true;

    this.dialog.open<DialogCreationFdncComponent, DialogDataCreationFdnc, boolean>(DialogCreationFdncComponent, dialogConfig);
  }

  init() {
    if (this.lignes) {
      this.sortLignes();
      this.initFormArray();
      this.dataSource.data = this.lignes;
      this.unselectLignes();
      this.updateDisabledFields();
    }
  }

  private sortLignes() {
    // Ordonnancement des lignes selon la désignation de l'article puis selon la DLC
    this.lignes.sort((l1, l2) => {
      const designation1 = this.designationPipe.transform(l1.article);
      const designation2 = this.designationPipe.transform(l2.article);

      const compareDesignation = designation1.localeCompare(designation2);

      if (compareDesignation === 0) {
        return !l1.dlc || l1.dlc < l2.dlc ? -1 : 1;
      } else {
        return compareDesignation;
      }
    });
  }

  private initFormArray() {
    const formArray = this.fb.array([]);

    for (const ligne of this.lignes) {

      const formGroup = this.fb.group({
        quantite: this.fb.control(
          this.calculValeurAPI(ligne.quantite),
          [Validators.min(0),
            CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_QUANTITE),
            CustomValidators.zeroInterdit(this.params.editQuantiteZeroInterdite),
            CustomValidators.validateQuantite(ligne)]
        ),
        prixHt:     this.fb.control(ligne.prixHt,  [Validators.min(0), CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_PRIX)]),
        prixTtc:    this.fb.control(ligne.prixTtc, [Validators.min(0), CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_PRIX)])
      });

      formGroup.setValidators(EntreeSortieLignesComponent.coherencePrix);

      formArray.push(formGroup);
    }

    this.form.setControl('lignes', formArray);
  }

  // Validation que le prix HT est bien inférieur au prix TTC
  //  le validateur sera affecté à la ligne mais en cas d'erreur, celle-ci sera répercutée sur les contrôles des 2 prix
  private static coherencePrix(formCtrl: AbstractControl): ValidationErrors {
    const prixHt  = formCtrl.get('prixHt').value;
    const prixTtc = formCtrl.get('prixTtc').value;

    if (prixHt === null || prixTtc === null) {
      EntreeSortieLignesComponent.removeErrorFromControls('ttc_inferieur_ht', formCtrl);
      return null;
    }

    if (prixHt > prixTtc) {
      EntreeSortieLignesComponent.addErrorToControls('ttc_inferieur_ht', formCtrl);
      return {'ttc_inferieur_ht': true};

    } else {
      EntreeSortieLignesComponent.removeErrorFromControls('ttc_inferieur_ht', formCtrl);
      return null;
    }
  }

  private static addErrorToControls(errorCode: string, formCtrl: AbstractControl): void {
    FormTools.addErrorToCtrl(errorCode, formCtrl.get('prixHt'));
    FormTools.addErrorToCtrl(errorCode, formCtrl.get('prixTtc'));
  }

  private static removeErrorFromControls(errorCode: string, formCtrl: AbstractControl): void {
    FormTools.removeErrorFromCtrl(errorCode, formCtrl.get('prixHt'));
    FormTools.removeErrorFromCtrl(errorCode, formCtrl.get('prixTtc'));
  }

  isEditable(): boolean {
    if (this.entete) {
      if (this.modeConsultation) {
        return false;
      } else {
        return this.entete.extra.editable.status;
      }

    } else {
      return true
    }
  }

  private updateDisabledFields() {
    if (!this.isEditable() || !this.droitSaisie) {
      this.form.get('lignes').disable();
      this.formCtrlFraisPort.disable();

    } else {
      this.form.get('lignes').enable();

      if (this.entete && this.entete.commande && this.entete.commande.sousLotZg.fraisPort) {
        this.crousService.getCurrentCrous('modificationLignesEJAutorisee').subscribe({
          next: data => {
            if (data.modificationLignesEJAutorisee) {
              this.formCtrlFraisPort.enable();
            } else {
              this.formCtrlFraisPort.disable();
            }
          }
        });
      } else {
        this.formCtrlFraisPort.disable();
      }
    }
  }

  /* ****************** */
  /* Colonnes affichées */
  /* ****************** */

  initColumnsData() {
    // Initialisation des informations sur les colonnes
    this.COLUMNS = {...this.params.editColumns}; // Une copie sans référence (et superficielle) est effectuée car des colonnes peuvent être supprimées
    this.COLUMNS_STORE_CODE = this.params.editColumnsStoreCode;

    // Dans le cas des bordereaux de livraison, il y a 3 cas possibles :
    //  BL sur commande externe
    //  BL sur commande interne
    //  BL sur transfert
    if (this.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT) {
      if (this.estBLSurCommandeExterne()) {
        this.afficherLigneFraisPort = this.entete.commande.sousLotZg.fraisPort;
        delete this.COLUMNS['quantiteAttendue'];
        delete this.COLUMNS['ecart'];
        delete this.COLUMNS['ecartDetaille'];
        delete this.COLUMNS['corrections'];

      } else {
        if (this.estBLSurTransfert()) {
          delete this.COLUMNS['quantiteCommandee'];
          delete this.COLUMNS['quantiteReceptionnee'];
          delete this.COLUMNS['quantiteAReceptionner'];
        }
        delete this.COLUMNS['prixHt'];
        delete this.COLUMNS['montantHt'];
        delete this.COLUMNS['tauxTva'];
        delete this.COLUMNS['prixTtc'];
        delete this.COLUMNS['montantTtc'];
        delete this.COLUMNS['fdnc'];
      }

      // Le code de stockage des colonnes sélectionnées dépend aussi du type de BL
      if (this.estBLSurCommandeExterne()) {
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_ES_BL_EXT;
      } else if (this.estBLSurTransfert()) {
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_ES_BL_TRF;
      } else {
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_ES_BL_INT;
      }
    }
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
      this.buildDisplayedColumnsFraisPort();
    }
  }

  public estBLSurCommandeExterne(): boolean {
    return this.entete && this.entete.commande && this.entete.commande.externe === true;
  }
  private estBLSurTransfert(): boolean {
    return this.entete
      && this.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT
      && this.entete.entreeSortieEnteteOrigine !== undefined && this.entete.entreeSortieEnteteOrigine !== null;
  }

  /* ************************************ */
  /* Quantités commandées (BL uniquement) */
  /* ************************************ */

  loadQuantitesCommandees() {
    if (!this.entete) {
      this.enteteLoaded.subscribe({
        next: () => {
          this.loadQuantitesCommandees();
        }
      });
    // Si les colonnes des BL sont affichés, que le BL est issu d'une commande et que l'utilisateur a le droit de consulter les commandes
    } else if (this.params.commonFieldsBLDisplayed && this.entete.commande && this.commandeService.droitConsultation()) {
      const idCommande = this.entete.commande.idCommandeEntete;
      const search = new CommandeLigneSearch();
      search.fields = 'article.idArticle,quantite';

      // Récupération des lignes de la commande
      this.commandeService.getListeLignes(idCommande, search).subscribe(listeLignesCommande => {
        for (const ligneCommande of listeLignesCommande) {
          // Affectation de la quantité commandée à la ligne de BL correspondante
          this.lignes.filter(ligneBL => ligneBL.article.idArticle === ligneCommande.article.idArticle)
            .forEach(ligneBL => ligneBL.quantiteCommandee = ligneCommande.quantite);
        }
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  /* *************************************** */
  /* Quantités en stock (sorties uniquement) */
  /* *************************************** */

  loadQuantitesEnStock() {
    if (!this.entete) {
      this.enteteLoaded.subscribe({
        next: () => {
          this.loadQuantitesEnStock();
        }
      });
    } else if (!this.params.editQuantitesPositives) {
      const search = new StockCompactSearch();
      search.idLieu = this.entete.lieu.idLieu;
      search.fields = 'article.idArticle,quantite';

      // Récupération des stocks
      this.stockService.getListeStocksCompacts(search).subscribe(listeStocksCompacts => {
        for (const ligne of this.lignes) {
          ligne.quantiteEnStock = 0;
        }

        for (const stock of listeStocksCompacts) {
          // Affectation de la quantité en stock à la ligne de sortie correspondante
          this.lignes.filter(ligneSortie => ligneSortie.article.idArticle === stock.article.idArticle)
            .forEach(ligneBL => ligneBL.quantiteEnStock = stock.quantite);
        }
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  /* ******************** */
  /* Sélection des lignes */
  /* ******************** */

  unselectLignes() {
    // si pas de lignes sélectionnée, on désélectionne tout
    if (!this.lignes.some(ligne => ligne.selected === true)) {
      this.selection.clear();
    }
  }

  // Si le nombre d'éléments sélectionnés correspond au nombre total de lignes
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Sélectionne toutes les lignes si elles ne sont pas sélectionnées sinon déselectionne toutes les lignes
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /* ********************* */
  /* Suppression de lignes */
  /* ********************* */

  openDialogSuppression(): void {
    if (this.selection.selected.length === 0 ) {
      return this.messageTool.sendErrorMessage("Vous devez sélectionner au moins une ligne");
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: this.selection.selected.length > 1
        ? "Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?"
        : "Êtes-vous sûr de vouloir supprimer la ligne sélectionnée ?"};

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) { // true si suppression confirmée, false sinon
        let listeObservables: Array<Observable<any>> = [];

        for (let ligne of this.selection.selected) {
          listeObservables.push(this.entreeSortieService.deleteLigne(this.entete, ligne));
        }

        forkJoin(listeObservables).subscribe(() => {
          if (listeObservables.length > 1) {
            this.messageTool.sendSuccess("Les lignes ont été supprimées avec succès", ClearMessages.TRUE);
          } else {
            this.messageTool.sendSuccess("La ligne a été supprimée avec succès", ClearMessages.TRUE);
          }

          this.forceUpdateTotaux++;

          // Envoi de la liste des lignes supprimées au composant parent
          this.lignesDeleted.emit(this.selection.selected);

        }, err => {
          this.messageTool.sendError(err);
        })
      }
    });
  }

  /* *************** */
  /* Ajout de lignes */
  /* *************** */

  openDialogAjoutLigne(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.data = new DialogDataLigneEntreeSortie();
    dialogConfig.data.action = Action.AJOUT;
    dialogConfig.data.params = this.params;
    dialogConfig.data.entete = this.entete;
    dialogConfig.data.lignesExistantes = this.lignes;

    const dialogRef = this.dialog.open(DialogAjoutLigneComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if (dialogConfig.data.ligneModifiee) {
        this.ligneInserted.emit(dialogConfig.data.ligneModifiee);
      } else if (dialogConfig.data.lignesInserees) {
        this.lignesInserted.emit(dialogConfig.data.lignesInserees);
      }
    });
  }

  /* ********************* */
  /* Modification de ligne */
  /* ********************* */

  debutUpdate(index: number, formControlName: string) {
    this.debutModification.emit();

    const formCtrl = this.getLigneCtrl(index).get(formControlName);
    // En cas d'erreur liée au webservice REST, celle-ci n'est plus mentionnée
    if (formCtrl.hasError('network') || formCtrl.hasError('5xx') || formCtrl.hasError('4xx')) {
      formCtrl.setErrors(null);
    }
  }

  finUpdate() {
    this.finModification.emit();
  }

  updateLigne(index: number, formControlName: string, goToNextCtrl: boolean = false) {
    const ligne = this.lignes[index];
    const ligneCtrl = this.getLigneCtrl(index);

    this.calculInformationsFinancieres(ligneCtrl, ligne);

    if (!ligneCtrl.valid) {
      this.messageTool.sendErrorMessage(`${this.messageTool.lignePrefix(ligne)} est erronée`);
      FormTools.markAsFailure(ligne);
      this.finUpdate();

    } else if (ligneCtrl.dirty) {
      ligne.quantite  = this.calculValeurAPI(ligneCtrl.get('quantite').value);
      if (this.params.editPrixSaisis) {
        ligne.prixHt  = ligneCtrl.get('prixHt').value;
        ligne.prixTtc = ligneCtrl.get('prixTtc').value;
      }

      this.entreeSortieService.putLigne(this.entete, ligne).subscribe(() => {
        const message = `${this.messageTool.lignePrefix(ligne)} a été mise à jour avec succès`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);

        // Les valeurs effectivement sauvegardées sont settées dans le formulaire
        ligneCtrl.get('quantite').setValue(this.calculValeurAPI(ligne.quantite));
        ligneCtrl.get('prixHt').setValue(ligne.prixHt);

        // La ligne est marquée comme n'ayant pas été touchée
        ligneCtrl.markAsPristine();

        // Mise à jour de l'écart
        ligne.ecart = EntreeSortieLignesComponent.calculEcart(ligne);

        this.forceUpdateTotaux++;

        // Envoi au composant parent qu'une ligne a été modifiée
        this.ligneUpdated.emit(ligne);

        if (goToNextCtrl) {
          this.goToNextCtrl(index, formControlName);
        }

      }, err => {
        ligneCtrl.get(formControlName).setErrors(FormTools.ngErrorFromHttpError(err));
        this.messageTool.sendError(err);
        FormTools.markAsFailure(ligne);

      }).add(() => {
        this.finUpdate();
      });

    } else {
      FormTools.unmark(ligne);
      this.finUpdate();

      if (goToNextCtrl) {
        this.goToNextCtrl(index, formControlName);
      }
    }
  }

  private getLigneCtrl(index: number): UntypedFormControl {
    return (this.form.get('lignes') as UntypedFormArray).controls[index] as UntypedFormControl;
  }

  private goToNextCtrl(index: number, formControlName: string): void {
    let inputs: QueryList<ElementRef>;
    switch (formControlName) {
      case 'quantite': inputs = this.quantiteInputs; break;
      case 'prixHt':   inputs = this.prixHtInputs;   break;
      case 'prixTtc':  inputs = this.prixTtcInputs;  break;
      default: return;
    }

    const nextIndex = index + 1;
    if (inputs.length > 0 && inputs.get(nextIndex) !== undefined) {
      this.renderer.selectRootElement(inputs.get(nextIndex).nativeElement).focus();
    }
  }

  private calculInformationsFinancieres(ligneCtrl: AbstractControl, ligne: EntreeSortieLigne): void {
    if (this.params.editPrixSaisis) {
      const prixHtCtrl = ligneCtrl.get('prixHt');

      if (prixHtCtrl.dirty) {
        const prixHt: number = prixHtCtrl.value;
        const tauxTva: number = ligne.article.tauxTva;

        if (prixHt !== null && tauxTva !== null) {
          const prixTtc = EntreeSortieLignesComponent.calculTtc(prixHt, tauxTva);
          ligneCtrl.get('prixTtc').setValue(prixTtc);
        }
      }
    }
  }

  private static calculTtc(prixHt: number, tauxTva: number): number {
    return +(prixHt * (100 + tauxTva) / 100).toFixed(EntreeSortieLignesComponent.NB_MAX_DECIMALES_PRIX);
  }

  private static calculEcart(ligne: EntreeSortieLigne) {
    return isNotNullOrUndefined(ligne.quantite) && isNotNullOrUndefined(ligne.quantiteAttendue) ? +(ligne.quantite - ligne.quantiteAttendue).toFixed(EntreeSortieLignesComponent.NB_MAX_DECIMALES_QUANTITE) : 0;
  }

  calculValeurAPI(value: number): number {
    return Tools.signerValeur(value, this.params.editQuantitesPositives);
  }

  updateMontants(index: number) {
    const qttCtrl = this.getQuantiteCtrl(index);
    const ligne = this.lignes[index];
    if (!qttCtrl || qttCtrl.value === null || !qttCtrl.valid && !qttCtrl.disabled) {
      ligne.montantTotalHt = null;
      ligne.montantTotalTtc = null;
    } else {
      ligne.montantTotalHt = this.calculMontantTotalHt(index);
      ligne.montantTotalTtc = this.calculMontantTotalTtc(index);
    }
  }

  private calculMontantTotalHt(index: number): number|null {
    const prixHt = this.getPrix(index, 'ht');

    if (prixHt === null) {
      return null;
    }

    const qttCtrl = this.getQuantiteCtrl(index);
    return qttCtrl.value * prixHt;
  }

  private calculMontantTotalTtc(index: number): number|null {
    // Dans le cas des BL, le montant TTC est calculé à partir du montant HT et de la TVA
    if (this.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT) {
      const ligne = this.lignes[index];
      return EntreeSortieLignesComponent.calculTtc(ligne.montantTotalHt, ligne.tauxTva);

    // Dans les autres cas le montant TTC est calculé à partir du PU TTC
    } else {
      const prixTtc = this.getPrix(index, 'ttc');

      if (prixTtc === null) {
        return null;
      }

      const qttCtrl = this.getQuantiteCtrl(index);
      return qttCtrl.value * prixTtc;
    }
  }

  private getQuantiteCtrl(index: number): AbstractControl {
    return this.getLigneCtrl(index).get('quantite');
  }

  private getPrix(index: number, type: 'ht'|'ttc'): number|null {
    const ligneCtrl = this.getLigneCtrl(index);
    const ligne = this.lignes[index];

    const prixCtrl = type === 'ht' ? ligneCtrl.get('prixHt') : ligneCtrl.get('prixTtc');

    if (prixCtrl) {
      if (!prixCtrl.valid && !prixCtrl.disabled) {
        return null;
      }

      return prixCtrl.value;
    }
    return type === 'ht' ? ligne.prixHt : ligne.prixTtc;
  }

  /* ************************* */
  /* Gestion des frais de port */
  /* ************************* */

  onDisplayedColumnChanged(): void {
    this.buildDisplayedColumnsFraisPort();
  }

  private buildDisplayedColumnsFraisPort(): void {
    this.displayedColumnsFraisPort = ['fillerFraisPort', 'libelleFraisPort'];
    this.nbColonnesLibelleFraisPort = -1;

    for (let col of this.displayedColumns) {
      if (col === 'montantHt') {
        this.displayedColumnsFraisPort.push('montantHtFraisPort');
      } else if (col === 'tauxTva') {
        this.displayedColumnsFraisPort.push('tauxTvaFraisPort');
      } else if (col === 'montantTtc') {
        this.displayedColumnsFraisPort.push('montantTtcFraisPort');
      }
      if (this.displayedColumnsFraisPort.length === 2) {
        this.nbColonnesLibelleFraisPort++;
      }
    }
  }

  debutUpdateFraisPort() {
    this.debutModification.emit();

    const formCtrl = this.formCtrlFraisPort;
    // En cas d'erreur liée au webservice REST, celle-ci n'est plus mentionnée
    if (formCtrl.hasError('network') || formCtrl.hasError('5xx') || formCtrl.hasError('4xx')) {
      formCtrl.setErrors(null);
    }
  }

  finUpdateFraisPort() {
    this.finModification.emit();
  }

  updateFraisPort() {
    if (!this.formCtrlFraisPort.valid) {
      this.messageTool.sendErrorMessage('Frais de port erroné');
      this.finUpdateFraisPort();

    } else if (this.formCtrlFraisPort.dirty) {
      this.entreeSortieService.patchEntete(this.entete, this.formCtrlFraisPort.value).subscribe(() => {
        this.messageTool.sendSuccess('Les frais de port ont été mis à jour avec succès', ClearMessages.TRUE);

        // La ligne est marquée comme n'ayant pas été touchée
        this.formCtrlFraisPort.markAsPristine();

        this.forceUpdateTotaux++;

        // Envoi au composant parent qu'une ligne a été modifiée
        this.ligneUpdated.emit(null);
        this.entete.fraisPort = this.formCtrlFraisPort.value;

      }, err => {
        this.formCtrlFraisPort.setErrors(FormTools.ngErrorFromHttpError(err));
        this.messageTool.sendError(err);

      }).add(() => {
        this.finUpdate();
      });

    } else {
      this.finUpdateFraisPort();
    }
  }

  /* **************** */
  /* Gestion des lots */
  /* **************** */

  openDialogGestionLots(ligne: EntreeSortieLigne) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new DialogDataLigneLotEntreeSortie();
    dialogConfig.data.ligne = ligne;
    dialogConfig.data.entete = this.entete;
    dialogConfig.data.params = this.params;
    const dialogRef = this.dialog.open(DialogGestionLotsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(quantiteLots => {
      if (quantiteLots !== null) {
        ligne.quantiteLots = quantiteLots;
        this.ligneUpdated.emit(ligne);
      }
    })
  }

  /* ************** */
  /* Détail d'écart */
  /* ************** */

  ecartDetaille(ligne: EntreeSortieLigne): number {
    return isNotNullOrUndefined(ligne.ecartDetaille) ? ligne.ecartDetaille : 0;
  }

  ecartDetailleOK(ligne: EntreeSortieLigne): boolean {
    return ligne.ecart === null || this.ecartDetaille(ligne) === ligne.ecart;
  }

  openDialogDetailEcart(ligne: EntreeSortieLigne) {
    this.entreeSortieService.getDetailsEcartLigne(this.entete, ligne, this.FIELDS_DETAILS_ECART).subscribe((data) => {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = new DialogDataLigneEntreeSortie();
      dialogConfig.data.action = Action.DETAIL_ECART;
      dialogConfig.data.params = this.params;
      dialogConfig.data.entete = this.entete;
      dialogConfig.data.ligne = ligne;
      dialogConfig.data.listeDetailsEcart = data;

      const dialogRef = this.dialog.open(DialogAjoutLigneComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        if (dialogConfig.data.ligneModifiee) {
          this.ligneInserted.emit(dialogConfig.data.ligneModifiee);
        }
      });
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  /* ******************* */
  /* Correction de ligne */
  /* ******************* */

  openDialogCorrection(ligneCorrigee: EntreeSortieLigne) {
    if (this.entete.extra.correctable.status) {
      this.entreeSortieService.getDetailsEcartLigne(this.entete, ligneCorrigee, this.FIELDS_DETAILS_ECART).subscribe((data) => {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.data = new DialogDataLigneEntreeSortie();
        dialogConfig.data.action = Action.CORRECTION;
        dialogConfig.data.params = this.params;
        dialogConfig.data.entete = this.entete;
        dialogConfig.data.ligne = ligneCorrigee;
        dialogConfig.data.listeDetailsEcart = data;
        dialogConfig.data.lignesExistantes = this.lignes;

        const dialogRef = this.dialog.open(DialogAjoutLigneComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
          if (dialogConfig.data.ligneModifiee) {
            this.ligneInserted.emit(dialogConfig.data.ligneModifiee);
          }
        });
      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.correctable.details);
    }
  }

  openDialogHistoriqueCorrections(ligne: EntreeSortieLigne) {
    const fields = 'idEntreeSortieLigne,' +
      'dlc,' +
      'quantite,' +
      'article.articleAchat,' +
      'article.articleVente,' +
      'article.codeArticleAchat,' +
      'article.codeArticleVente,' +
      'article.designationAchat,' +
      'article.designationVente,' +
      'article.articleDlc,' +
      'dateCreation,' +
      'utilisateurCreation';

    this.entreeSortieService.getCorrectionsLigne(this.entete, ligne, fields).subscribe((data) => {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = new DialogDataHistoriqueCorrectionsEntreeSortie();
      dialogConfig.data.params = this.params;
      dialogConfig.data.entete = this.entete;
      dialogConfig.data.lignes = data;

      this.dialog.open(DialogHistoriqueCorrectionsComponent, dialogConfig);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Test si un control du FormArray des lignes a une erreur
  hasError(index: number, controlName: string, errorCode: string): boolean {
    return this.getLigneCtrl(index).get(controlName).hasError(errorCode);
  }


  openDialogConfirmationCopie() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de copie",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: "Êtes-vous sûr de vouloir copier toutes les quantités commandées dans les quantités livrées ?"
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.copieCommandeesDansReceptionnees();
      }
    });

  }

  private copieCommandeesDansReceptionnees() {
    let index = 0;
    for (const ligne of this.lignes) {
      this.copieCommandeeDansReceptionnee(ligne, index);
      index++;
    }
  }

  copieCommandeeDansReceptionnee(ligne: EntreeSortieLigne, index: number) {
    const ligneCtrl = this.getLigneCtrl(index);
    const formCtrl = ligneCtrl.get('quantite');
    if (formCtrl.value !== ligne.quantiteCommandee) {
      formCtrl.setValue(ligne.quantiteCommandee);
      ligneCtrl.markAsDirty();
      this.updateLigne(index, 'quantite');
      this.updateMontants(index);
    }
  }
}
