import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Utilisateur} from '../model/Utilisateur';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {UtilisateurAuth} from '../model/UtilisateurAuth';
import {CacheService} from "./cache.service";
import {Droit} from "../model/Droit";
import {CookieService} from "ngx-cookie-service";
import {JwtInterceptorService} from "../intercepteurs/jwt-interceptor.service";
import {MessageTool} from "../commons/MessageTool";
import {TimeFormatPipe} from "../commons/pipes/TimeFormatPipe";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageHelper} from "../helpers/local-storage-helper";
import {db} from "./database";
import {catchError, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userEvents = new BehaviorSubject<Utilisateur>(undefined);
  utilisateurCourant: Utilisateur = null;
  mapRuAutorises:UgMap = {}; // [idUg] => listeIdRu

  private readonly NB_SECONDES_SESSION_EXPIRE_BIENTOT = 300;
  private timeoutIdWarning: NodeJS.Timeout;
  private timeoutIdLogout: NodeJS.Timeout;

  readonly cookieName: string = JwtInterceptorService.buildCookieName();

  constructor(private http: HttpClient,
              private router: Router,
              private cacheService: CacheService,
              private cookieService: CookieService,
              private messageTool: MessageTool,
              private timeFormatPipe: TimeFormatPipe, private dialogRef: MatDialog) { }

  private storeLoggedInUser(user: Utilisateur) {
    this.utilisateurCourant = user;
    this.mapRuAutorises = this.generateMapRusAutorises(user);
    window.sessionStorage.setItem('rememberMe', JSON.stringify(user));
    this.cacheService.clear();
    this.startLogoutTimers();
    this.userEvents.next(user);
  }

  private generateMapRusAutorises(user: Utilisateur): UgMap {
    let map:UgMap = {};
    if (user && user.listeRu) {
      for (let ru of user.listeRu) {
        if (!map[ru.ug.idUg]) {
          map[ru.ug.idUg] = [];
        }
        map[ru.ug.idUg].push(ru.idRu);
      }
    }
    return map;
  }

  logOut(): void {
    this.stopTimers();
    this.utilisateurCourant = null;
    this.mapRuAutorises = {};
    this.userEvents.next(null);
    sessionStorage.clear();
    this.cookieService.delete(this.cookieName, '/', environment.domain);
    this.truncateDb();
    this.dialogRef.closeAll();
    this.router.navigate(['/choix-etablissement']);
  }

  private me(): Observable<UtilisateurAuth> {
    return this.http.get<UtilisateurAuth>(environment.bnsAuthApiUrl + '/me');
  }

  devLogin(eppn: string|null, devToken: string|null, pwd: string|null, returnPage: string) {
    // On vérifie qu'on dispose bien d'un crous en cache, si non on demande à l'utilisateur de le renseigner :
    if (localStorage.getItem('idCrous') == null || parseInt(localStorage.getItem('idCrous')) == 0) {
      this.router.navigate(['/choix-etablissement']);
      return;
    }

    if (eppn !== null) {
      window.location.href = environment.bnsAuthLoginUrl + encodeURIComponent(returnPage)
        + '&idEtablissement=' + LocalStorageHelper.getIdEtablissement()
        + '&eppn=' + encodeURIComponent(eppn)
        + (pwd !== null ? '&pwd=' + encodeURIComponent(pwd) : '');
      return;
    }

    if (devToken && devToken.length > 0) {
      try {
        const user = this.devTokenToUserEpona(devToken);
        this.storeLoggedInUser(user);
        sessionStorage.setItem('devToken', devToken.replace(/[\n\r\t ]/g, ''));
        this.router.navigate(['accueil']);

      } catch(err) {
        alert('token non valide');
        console.error(err);
      }
    }
  }

  private devTokenToUserEpona(token: string): Utilisateur {
    const devToken: DevToken = JSON.parse(token);

    const user = new Utilisateur();

    user.eppn = devToken.appId;

    user.listeDroits = [];
    if (devToken.roles && devToken.roles.length > 0) {
      devToken.roles.split(',').forEach(role => {
        const droit = new Droit();
        droit.idDroit = +role;
        user.listeDroits.push(droit);
      });
    }

    user.listeRu = [];
    if (devToken.bat && devToken.bat.length > 0) {
      devToken.bat.split(',').forEach(b => {
        user.listeIdBatiment.push(+b);
      });
    }

    return user;
  }

  private userAuthToUserEpona(userAuth: UtilisateurAuth): Utilisateur {
    const user = new Utilisateur();
    user.eppn = userAuth.eppn;
    user.listeIdDroit = userAuth.listeIdDroit ? userAuth.listeIdDroit : [];
    user.listeIdBatiment = userAuth.listeIdBatiment;
    user.dateExpiration = new Date(userAuth.expirationTime ? userAuth.expirationTime : userAuth.dateExpiration);
    return user;
  }

  private rememberMeToUserEpona(): Utilisateur {
    const obj = JSON.parse(sessionStorage.getItem('rememberMe'));

    const user = new Utilisateur();
    user.eppn        = obj.eppn;
    user.listeDroits = obj.listeDroits;
    user.listeRu     = obj.listeRu;
    user.listeIdDroit = obj.listeIdDroit;
    user.listeIdBatiment = obj.listeIdBatiment;
    user.dateExpiration = new Date(obj.expirationTime ? obj.expirationTime : obj.dateExpiration);

    return user;
  }

  // Démarrage des timers liés à la déconnexion (expiration du token)
  private startLogoutTimers(): void {
    // Arrêt des éventuels timers existant
    this.stopTimers();

    // Si aucune date d'expiration n'est renseignée, les timers ne sont pas démarrés
    if (!this.utilisateurCourant.dateExpiration) {
      return;
    }

    // Délais en millisecondes
    const durationBeforeLogout = this.utilisateurCourant.dateExpiration.getTime() - new Date().getTime();
    const durationBeforeWarning = durationBeforeLogout - this.NB_SECONDES_SESSION_EXPIRE_BIENTOT * 1000;

    // Affichage d'un message d'avertissement lorsque le délai avant avertissement est écoulé
    this.timeoutIdWarning = setTimeout(() => {
      const message = 'Votre session expirera à ' + this.timeFormatPipe.transform(this.utilisateurCourant.dateExpiration);
      this.messageTool.sendWarning(message);
    }, durationBeforeWarning);

    // Affichage d'un message d'avertissement et déconnexion lorsque le délai avant déconnexion est écoulé
    this.timeoutIdLogout = setTimeout(() => {
      this.messageTool.sendWarning('Votre session a expiré');
      this.logOut();
    }, durationBeforeLogout);
  }

  // Arrêt des timers liés à la déconnexion
  private stopTimers(): void {
    clearTimeout(this.timeoutIdWarning);
    clearTimeout(this.timeoutIdLogout);
  }

  private handleActivation(droitNecessaire: string): boolean {
    // Si aucun droit n'est nécessaire
    if (droitNecessaire === undefined) {
      return true;

    // Sinon, vérification que le droit fait partie des droits de l'utilisateur connecté
    } else {
      if (Array.isArray(droitNecessaire)){
        for (const d of droitNecessaire) {
          if (this.utilisateurCourant.possedeDroit(d)) {
            return true;
          }
        }

      } else {
        if (this.utilisateurCourant.possedeDroit(droitNecessaire)) {
          return true;
        }
      }

      this.messageTool.sendErrorMessage('Accés refusé : vous ne disposez pas des droits suffisants');
      console.error(`Droit nécessaire : ${droitNecessaire}`);
      return false;
    }
  }

  isAuth(droitNecessaire: string) {

    if (this.isDevOrInt()) {
      // Connexion par EPPN
      if (!this.utilisateurCourant && sessionStorage.getItem('rememberMe')) {
        this.storeLoggedInUser(this.rememberMeToUserEpona());
      }
    }

    if (this.utilisateurCourant) {
      return this.handleActivation(droitNecessaire);
    }

    return this.me().pipe(
      tap((user: UtilisateurAuth) => {
        this.storeLoggedInUser(this.userAuthToUserEpona(user));
      }),
      map(res => {
        // Si l'utilisateur n'a aucun droit, il est déconnecté
        if (!res.listeIdDroit || res.listeIdDroit.length === 0) {
          this.messageTool.sendErrorMessage(`Aucun droit trouvé pour l\'utilisateur ${res.eppn}`);
          this.logOut();
          return false;
        }

        if (res) {
          return this.handleActivation(droitNecessaire);
        } else {
          console.error("/me error");
          return false;
        }
      }),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }

  private isDevOrInt(): boolean {
    return environment.env === 'DEV' || environment.env === 'INT';
  }

  private truncateDb() {
    db.articles.clear();
    db.inventaireLignes.clear();
    db.inventaires.clear();
    db.lieu.clear();
    db.inventaireUtilisateur.clear();
  }
}

interface UgMap {
  [key: number]: Array<number>;
}

class DevToken {
  appId: string;
  roles: string;
  ruAutorises: string;
  bat: string;
}
