<epona-select-lieu [lieu]="lieuCourant"
                   [disabled]="!!entete"
                   (lieuSubmitted)="onLieuSubmitted($event)"></epona-select-lieu>

<h1 *ngIf="!entete">{{ params.editCreationTitle }}</h1>
<h1 *ngIf="entete && droitModification">{{ params.editModificationTitle }}</h1>
<h1 *ngIf="entete && !droitModification">{{ params.editConsultationTitle }}</h1>

<div class="fiche">
  <epona-entree-sortie-entete [params]="params"
                              [entete]="entete"
                              [lignes]="lignes"
                              [entreeSortieLiee]="entreeSortieLiee"
                              [modificationLigneEnCours]="modificationLigneEnCours"
                              [lieuCourant]="lieuCourant"
                              (enteteSubmitted)="doHandleEntete($event)"
                              (validationSubmitted)="doValidation($event)"
                              (suppressionSubmitted)="doSuppression($event)"
                              (serviceFaitUpdated)="doServiceFaitUpdated($event)"
                              (listeDocumentsUpdated)="doListeDocumentsUpdated($event)"
                              class="entete">
  </epona-entree-sortie-entete>

  <!-- Le composant des lignes n'est affiché que si l'on est en modification donc s'il y a un idEntete -->
  <div class="lignes">
    <mat-card *ngIf="idEntete">
      <mat-tab-group [mat-stretch-tabs]="false" (selectedIndexChange)="indexChange($event)">
        <mat-tab label="Lignes">
          <epona-entree-sortie-lignes [params]="params"
                                      [entete]="entete"
                                      [lignes]="lignes"
                                      (ligneInserted)="doHandleLigneInserted($event)"
                                      (lignesInserted)="doHandleLignesInserted($event)"
                                      (ligneUpdated)="doHandleLigneUpdated($event)"
                                      (lignesDeleted)="doHandleLignesDeleted($event)"
                                      (debutModification)="doDebutModificationLigne()"
                                      (finModification)="doFinModificationLigne()">
          </epona-entree-sortie-lignes>
        </mat-tab>

        <mat-tab label="FDNC" *ngIf="params.editFdncDisplayed">
          <div *ngIf="displayFdnc">
            <epona-fdnc-commande-livraison [livraison]="entete"></epona-fdnc-commande-livraison>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
