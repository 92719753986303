<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span *ngIf="!courriel?.interne">Courriel au fournisseur</span>
    <span *ngIf="courriel?.interne">Courriel de notification interne</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div class="alert alert-info" role="alert" *ngIf="courriel && courriel.dateEnvoi == null">
  <ng-container *ngIf="estEnvironnementProduction">
    <p *ngIf="!courriel.interne">
      En cas d'approbation de l'engagement juridique dans Orion,
      la commande sera envoyée automatiquement par courriel au fournisseur par Épona.
    </p>
    <p *ngIf="courriel.interne">
      En cas d'approbation de l'engagement juridique dans Orion, la commande sera transmise automatiquement
      en <abbr title="Échange de données informatisé">EDI</abbr> au fournisseur par Épona
      et un courriel de notification sera adressé en interne.
    </p>
  </ng-container>
  <ng-container *ngIf="!estEnvironnementProduction">
    <ng-container *ngIf="!courriel.interne">
      <p>
        En environnement de production et en cas d'approbation de l'engagement juridique dans Orion,
        la commande serait envoyée automatiquement par courriel au fournisseur par Épona.
      </p>
      <p>
        S'agissant d'un environnement de test, le courriel ne sera envoyé qu'au demandeur.
      </p>
    </ng-container>
    <ng-container *ngIf="courriel.interne">
      <p>
        En environnement de production et en cas d'approbation de l'engagement juridique dans Orion,
        la commande serait transmise automatiquement en <abbr title="Échange de données informatisé">EDI</abbr>
        au fournisseur par Épona et un courriel de notification serait adressé en interne.
      </p>
      <p>
        S'agissant d'un environnement de test, la commande ne sera pas transmise en EDI
        et le courriel de notification ne sera envoyé qu'au demandeur.
      </p>
    </ng-container>
  </ng-container>
</div>
<div class="alert alert-info" role="alert" *ngIf="courriel && courriel.dateEnvoi != null">
  <a href="#" (click)="$event.preventDefault();downloadMail()">Ce courriel</a> a été envoyé
  {{ estEnvironnementProduction && !courriel.interne ? 'au fournisseur' : '' }} le {{ courriel.dateEnvoi | dateTimeFormat }}.
</div>

<form [formGroup]="dialogForm">
  <mat-dialog-content>
    <div class="conteneur-colonne">
      <div class="colonne">
        <mat-form-field>
          <mat-label>Mail expéditeur</mat-label>

          <input matInput
                 type="email"
                 maxlength="100"
                 [required]="estPartiellementModifiable()"
                 value="ne-pas-repondre@lescrous.fr"
                 disabled="disabled">

          <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>
        </mat-form-field>

        <mat-form-field >
          <mat-label>Mail destinataire (fournisseur)</mat-label>

          <input matInput
                 type="email"
                 maxlength="100"
                 [required]="estPartiellementModifiable()"
                 formControlName="mailFournisseur">

          <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>

          <mat-error *ngIf="dialogForm.get('mailFournisseur').hasError('required')">Information obligatoire</mat-error>
          <mat-error *ngIf="dialogForm.get('mailFournisseur').hasError('email')">Adresse mail non valide</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mail demandeur (Crous)</mat-label>

          <input matInput
                 type="email"
                 maxlength="100"
                 [required]="estPartiellementModifiable()"
                 formControlName="mailDemandeur">

          <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>

          <mat-error *ngIf="dialogForm.get('mailDemandeur').hasError('required')">Information obligatoire</mat-error>
          <mat-error *ngIf="dialogForm.get('mailDemandeur').hasError('email')">Adresse mail non valide</mat-error>
        </mat-form-field>


        <mat-form-field>
          <mat-label>Téléphone contact livraison (Crous)</mat-label>

          <input matInput
                 maxlength="50"
                 [required]="estPartiellementModifiable()"
                 formControlName="telephoneContactLivraison">

          <mat-icon matSuffix class="material-icons-outlined">phone</mat-icon>

          <mat-error *ngIf="dialogForm.get('telephoneContactLivraison').hasError('required')">Information obligatoire</mat-error>
          <mat-error *ngIf="dialogForm.get('telephoneContactLivraison').hasError('pattern')">Téléphone non valide</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mails supp. (séparés par un point-virgule)</mat-label>

          <input matInput
                 type="text"
                 maxlength="255"
                 formControlName="mailsDemandeursSupp">

          <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>

          <mat-error *ngIf="dialogForm.get('mailsDemandeursSupp').hasError('emails')">Adresses mails non valides</mat-error>
        </mat-form-field>
      </div>

      <div class="colonne">
        <mat-form-field class="contains-link" *ngIf="nomFichier">
          <mat-label>Fichier PDF</mat-label>

          <input matInput value="{{ nomFichier }}" disabled="disabled" />

          <a matSuffix href="#" (click)="$event.preventDefault();downloadPdf()">
            <mat-icon>picture_as_pdf</mat-icon>
          </a>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sujet</mat-label>

          <input matInput
                 type="text"
                 maxlength="255"
                 [required]="estPartiellementModifiable()"
                 formControlName="sujet">

          <mat-error *ngIf="dialogForm.get('sujet').hasError('required')">Information obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Message</mat-label>

          <textarea matInput cdkTextareaAutosize
                    formControlName="message"
                    cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="10"
                    maxlength="1000"
                    [required]="estPartiellementModifiable()">
          </textarea>

          <mat-error *ngIf="dialogForm.get('message').hasError('required')">Information obligatoire</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
</form>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!dialogForm.valid" *ngIf="estBrouillon() || estPartiellementModifiable()">Enregistrer</button>
  <button mat-raised-button color="primary" (click)="envoyerDeNouveau()" *ngIf="estEnvoyableDeNouveau()">Envoyer de nouveau</button>
</mat-dialog-actions>
