import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {HttpService} from "../http.service";
import {MessageTool} from "../../commons/MessageTool";
import {Article} from "../../model/epona-api/Article";
import {Observable} from "rxjs";
import {ArticleSearch} from "../../model/epona-api/ArticleSearch";
import {ArticlePUT} from "../../model/epona-api/ArticlePUT";
import {ArticlePOST} from "../../model/epona-api/ArticlePOST";
import {OnlineService} from "../online.service";
import {liveQuery} from "dexie";
import {db} from "../database";
import {tap} from "rxjs/operators";

@Injectable()
export class ArticleService {

  private env = environment;
  private readonly baseUrl: string;

  private isOnline = true;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService,
              private onlineService: OnlineService) {
    this.baseUrl = this.env.eponaApiUrl + '/articles';
    onlineService.isOnline.subscribe(r => this.isOnline=r);
  }

  /* ******************** */
  /* Gestion des articles */
  /* ******************** */

  getListeArticles(search: ArticleSearch): Observable<Article[]> {
    if (this.isOnline) {
      const params = this.httpService.buildParams(search);
      return this.httpClient.get<Article[]>(this.baseUrl, {params}).pipe(
        tap(articles => {
          db.articles.clear();
          db.articles.bulkAdd(articles).then( r => {
          }). catch( e=> {
            console.error('Impossible d\'insérer les articles dans la BDD locale');
            console.error(e);
          })
        })
      );
    } else {
      // @ts-ignore
      return liveQuery<Article[]>(() => db.articles.toArray());
    }
  }

  getArticle(idArticle: number, fields: string): Observable<Article> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<Article>(this.baseUrl + '/' + idArticle, {params});
  }

  postArticle(article: Article, utilisation: string): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl, new ArticlePOST(article, utilisation));
  }

  putArticle(article: Article, utilisation: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + article.idArticle, new ArticlePUT(article, utilisation));
  }
}
