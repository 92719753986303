import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {TypeMouvement} from "../../model/TypeMouvement";
import {environment} from "../../../environments/environment";
import {ProchaineRevisionTarifaire} from "../../model/epona-api/prochaine-revision-tarifaire";
import {HttpService} from "../http.service";
import {ProchaineRevisionTarifaireSearch} from "../../model/epona-api/prochaine-revision-tarifaire-search";

@Injectable({
  providedIn: 'root'
})
export class RevisionTarifaireService {

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getProchainesRevisionsTarifiaires(search: ProchaineRevisionTarifaireSearch): Observable<ProchaineRevisionTarifaire[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<ProchaineRevisionTarifaire[]>(environment.eponaApiUrl + '/prochaines-revisions-tarifaires', {params});
  }

}
