import {Article} from './Article';
import {ILigneModifiable} from "./ILigneModifiable";
import {UniteMesure} from "./UniteMesure";

export class CommandeLigne implements ILigneModifiable {
  idCommandeLigne: number;

  article: Article;
  reference: string;
  colisage: number;
  quantite: number;
  uniteConditionnement: UniteMesure;
  prixAchatHt: number;
  tauxTva: number;
  prixAchatTtc: number;
  valeurHt: number;
  commentaire: string;
  quantiteMagasin: number;
  commentaireMagasin: string;
  dateMajPrix: Date;

  estPrixRecent: boolean;
  ageMaxPrix: number;

  quantiteStock: number;
  quantiteReceptionnee: number;

  selected: boolean;
  success: boolean = null;
}
