import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

import { Departement } from '../model/Departement';
import { Famille } from '../model/Famille';
import { SousFamille } from '../model/SousFamille';

@Injectable()
export class W3Service {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Méthode permettant de récupérer les départements
   */
  getDepartements(): Observable<Departement[]> {
    return this.httpClient.get<Departement[]>(environment.w3ApiUrl + '/referentiel/departements');
  }

  /**
   * Méthode permettant de récupérer les familles d'un département
   * @param codeDepartement
   */
  getFamilles(codeDepartement: number): Observable<Famille[]>{
    return this.httpClient.get<Famille[]>(environment.w3ApiUrl + '/referentiel/departements/' + codeDepartement + '/familles', {
      params: new HttpParams().set('fields', 'codeFamille,libelle')
    });
  }

  /**
   * Méthode permettant de récupérer les sous-familles d'une famille
   * @param codeFamille
   */
  getSousFamilles(codeFamille: number): Observable<SousFamille[]> {
    return this.httpClient.get<SousFamille[]>(environment.w3ApiUrl + '/referentiel/familles/' + codeFamille + '/sous-familles', {
      params: new HttpParams().set('fields', 'codeSousFamille,libelle')
    });
  }
}
