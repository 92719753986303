import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccueilComponent} from './accueil/accueil.component';
import {canActivateRouteGuard} from './intercepteurs/auth.guard';
import {EppnFormComponent} from './identification/eppn-form/eppn-form.component';
import {VentesTempsReelComponent} from './ventes-temps-reel/ventes-temps-reel.component';
import {CodeDroit} from "./commons/constants/CodeDroit";
import {ListeInventairesComponent} from './inventaires/liste-inventaires/liste-inventaires.component';
import {ListeCommandesComponent} from "./commandes/liste-commandes/liste-commandes.component";
import {LieuxArticlesComponent} from "./lieux-articles/lieux-articles.component";
import {InventaireComponent} from "./inventaires/inventaire/inventaire.component";
import {CommandeComponent} from "./commandes/commande/commande.component";
import {canDeactivateGuard} from "./intercepteurs/can-deactivate/can-deactivate.guard";
import {ListeEntreesSortiesComponent} from "./entrees-sorties/liste-entrees-sorties/liste-entrees-sorties.component";
import {EntreeSortieComponent} from "./entrees-sorties/entree-sortie/entree-sortie.component";
import {EntreeSortieParametrage} from "./model/epona-ui/EntreeSortieParametrage";
import {ListeStocksComponent} from "./etat-stocks/liste-stocks/liste-stocks.component";
import {ListeFournisseursComponent} from "./fournisseurs/liste-fournisseurs/liste-fournisseurs.component";
import {FournisseurComponent} from "./fournisseurs/fournisseur/fournisseur.component";
import {TopbarMenu} from "./commons/enum/TopbarMenu";
import {ListeArticlesComponent} from "./articles/liste-articles/liste-articles.component";
import {ListePaniersComponent} from "./paniers/liste-paniers/liste-paniers.component";
import {PanierComponent} from "./paniers/panier/panier.component";
import {ListeLieuxComponent} from "./lieux/liste-lieux/liste-lieux.component";
import {UtilisationArticle} from "./commons/constants/UtilisationArticle";
import {TopbarSubmenu} from "./commons/enum/TopbarSubmenu";
import {ChoixEtablissementComponent} from "./connexion/choix-etablissement/choix-etablissement.component";
import {appProperties} from "./app.properties";
import {
  EntreeSortieRechercheLotComponent
} from "./entrees-sorties/entree-sortie-recherche-lot/entree-sortie-recherche-lot.component";
import {RechercheFdncComponent} from './fdnc/recherche-fdnc/recherche-fdnc.component';
import {FicheFdncComponent} from './fdnc/fiche-fdnc/fiche-fdnc.component';


const routes: Routes = [
  {path: '', redirectTo: '/choix-etablissement', pathMatch: 'full'},
  {path: 'choix-crous', redirectTo: '/choix-etablissement', pathMatch: 'full'},
  {
    path: 'choix-etablissement',
    title: appProperties.nomApplication + ' - Sélection de l\'établissement',
    component: ChoixEtablissementComponent
  },
  {
    path: 'login',
    title: appProperties.nomApplication + ' - Connexion tests',
    component: EppnFormComponent
  },
  {
    path: 'accueil',
    title: appProperties.nomApplication + ' - Accueil',
    component: AccueilComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.ACCUEIL}
  },
  {
    path: 'etat-stocks',
    title: appProperties.nomApplication + ' - État des stocks',
    component: ListeStocksComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.STOCKS, droit: CodeDroit.STOCKS_CONSULTATION}
  },
  {
    path: 'ventes-temps-reel',
    title: appProperties.nomApplication + ' - Liste des ventes en temps réel',
    component: VentesTempsReelComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.VENTES, droit: CodeDroit.VISUALISATION_VENTES}
  },
  {
    path: 'lieux-articles',
    title: appProperties.nomApplication + ' - Paramétrage des articles dans le lieu de stockage',
    component: LieuxArticlesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.LIEUX_ARTICLES, droit: CodeDroit.GESTION_PARAMETRAGES_LIEUX_ARTICLES}
  },
  {
    path: 'liste-articles-achat',
    title: appProperties.nomApplication + ' - Liste des articles à l\'achat',
    component: ListeArticlesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.ARTICLES_ACHAT, droit: CodeDroit.GESTION_ARTICLES, utilisation: UtilisationArticle.ACHAT},
  },
  {
    path: 'liste-articles-vente',
    title: appProperties.nomApplication + ' - Liste des articles à la vente',
    component: ListeArticlesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.ARTICLES_VENTE, droit: CodeDroit.GESTION_ARTICLES, utilisation: UtilisationArticle.VENTE},
  },
  {
    path: 'liste-lieux',
    title: appProperties.nomApplication + ' - Liste des lieux',
    component: ListeLieuxComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.LIEUX, droit: CodeDroit.GESTION_LIEUX},
  },
  {
    path: 'liste-commandes',
    title: appProperties.nomApplication + ' - Liste des commandes',
    component: ListeCommandesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.COMMANDES, droit: CodeDroit.COMMANDES_CONSULTATION}
  },
  {
    path: 'creation-commande',
    title: appProperties.nomApplication + ' - Création d\'une commande',
    component: CommandeComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.COMMANDES, droit: CodeDroit.COMMANDES_SAISIE},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'commande/:id',
    title: appProperties.nomApplication + ' - Consultation d\'une commande',
    component: CommandeComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.COMMANDES, droit: CodeDroit.COMMANDES_CONSULTATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-paniers',
    title: appProperties.nomApplication + ' - Liste des paniers',
    component: ListePaniersComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.PANIERS, droit: CodeDroit.PANIERS_CONSULTATION}
  },
  {
    path: 'creation-panier',
    title: appProperties.nomApplication + ' - Création d\'un panier',
    component: PanierComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.PANIERS, droit: CodeDroit.PANIERS_SAISIE},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'panier/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un panier',
    component: PanierComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.COMMANDES, submenu: TopbarSubmenu.PANIERS, droit: CodeDroit.PANIERS_CONSULTATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-entrees-sorties',
    title: appProperties.nomApplication + ' - Liste des entrées/sorties',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu:TopbarMenu.E_S, droit: CodeDroit.LIVRAISONS_CONSULTATION}
  },
  {
    path: 'creation-entree-sortie',
    title: appProperties.nomApplication + ' - Création d\'une entrée/sortie',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu:TopbarMenu.E_S, droit: CodeDroit.LIVRAISONS_SAISIE},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'entree-sortie/:id',
    title: appProperties.nomApplication + ' - Consultation d\'une entrée/sortie',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu:TopbarMenu.E_S, droit: CodeDroit.LIVRAISONS_CONSULTATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-bordereaux-livraison',
    title: appProperties.nomApplication + ' - Liste des bordereaux de livraison',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.LIVRAISONS, submenu: TopbarSubmenu.LIVRAISONS, droit: CodeDroit.LIVRAISONS_CONSULTATION, params: EntreeSortieParametrage.BORDEREAUX_LIVRAISON}
  },
  {
    path: 'bordereau-livraison/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de livraison',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.LIVRAISONS, submenu: TopbarSubmenu.LIVRAISONS, droit: CodeDroit.LIVRAISONS_CONSULTATION, params: EntreeSortieParametrage.BORDEREAUX_LIVRAISON},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'recherche-lots',
    title: appProperties.nomApplication + ' - Recherche des lots',
    component: EntreeSortieRechercheLotComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.LIVRAISONS, submenu: TopbarSubmenu.LOTS, droit: CodeDroit.LIVRAISONS_CONSULTATION, params: EntreeSortieParametrage.BORDEREAUX_LIVRAISON}
  },
  {
    path: 'liste-fdnc',
    title: appProperties.nomApplication + ' - Liste des FDNC',
    component: RechercheFdncComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.LIVRAISONS, submenu: TopbarSubmenu.FDNC, droit: CodeDroit.FDNC_CONSULTATION}
  },
  {
    path: 'fdnc/:id',
    title: appProperties.nomApplication + ' - Consultation d\'une FDNC',
    component: FicheFdncComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu:TopbarMenu.LIVRAISONS, submenu: TopbarSubmenu.FDNC, droit: CodeDroit.FDNC_CONSULTATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-bordereaux-transfert',
    title: appProperties.nomApplication + ' - Liste des bordereaux de transfert',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.TRANSFERTS, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.TRANSFERTS}
  },
  {
    path: 'creation-bordereau-transfert',
    title: appProperties.nomApplication + ' - Création d\'un bordereau de transfert',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.TRANSFERTS, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.TRANSFERTS},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'bordereau-transfert/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de transfert',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.TRANSFERTS, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.TRANSFERTS},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-bordereaux-sortie',
    title: appProperties.nomApplication + ' - Liste des bordereaux de sortie évènementielle',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.SORTIES_EVENEMENTIELLES, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.SORTIES_EVENEMENTIELLES}
  },
  {
    path: 'creation-bordereau-sortie',
    title: appProperties.nomApplication + ' - Création d\'un bordereau de sortie évènementielle',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.SORTIES_EVENEMENTIELLES, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.SORTIES_EVENEMENTIELLES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'bordereau-sortie/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de sortie évènementielle',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.SORTIES_EVENEMENTIELLES, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.SORTIES_EVENEMENTIELLES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-retours-sorties',
    title: appProperties.nomApplication + ' - Liste des retours de sortie',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.RETOURS_SORTIES, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.RETOURS_SORTIES}
  },
  {
    path: 'retour-sortie/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un retour de sortie',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.RETOURS_SORTIES, droit: CodeDroit.GESTION_TRANSFERTS, params: EntreeSortieParametrage.RETOURS_SORTIES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-pertes',
    title: appProperties.nomApplication + ' - Liste des bordereaux de pertes',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.PERTES, droit: CodeDroit.GESTION_PERTES, params: EntreeSortieParametrage.PERTES}
  },
  {
    path: 'creation-pertes',
    title: appProperties.nomApplication + ' - Création d\'un bordereau de pertes',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.PERTES, droit: CodeDroit.GESTION_PERTES, params: EntreeSortieParametrage.PERTES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'pertes/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de pertes',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.PERTES, droit: CodeDroit.GESTION_PERTES, params: EntreeSortieParametrage.PERTES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-consommations-utilisations',
    title: appProperties.nomApplication + ' - Liste des bordereaux de consommations/utilisations',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.CONSOMMATIONS_UTILISATIONS, droit: CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS, params: EntreeSortieParametrage.CONSOMMATIONS_UTILISATIONS}
  },
  {
    path: 'creation-consommations-utilisations',
    title: appProperties.nomApplication + ' - Création d\'un bordereau de consommations/utilisations',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.CONSOMMATIONS_UTILISATIONS, droit: CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS, params: EntreeSortieParametrage.CONSOMMATIONS_UTILISATIONS},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'consommations-utilisations/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de consommations/utilisations',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.CONSOMMATIONS_UTILISATIONS, droit: CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS, params: EntreeSortieParametrage.CONSOMMATIONS_UTILISATIONS},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-reintegrations',
    title: appProperties.nomApplication + ' - Liste des bordereaux de réintégrations',
    component: ListeEntreesSortiesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.REINTEGRATIONS, droit: CodeDroit.GESTION_REINTEGRATIONS, params: EntreeSortieParametrage.REINTEGRATION}
  },
  {
    path: 'creation-reintegrations',
    title: appProperties.nomApplication + ' - Création d\'un bordereau de réintégrations',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.REINTEGRATIONS, droit: CodeDroit.GESTION_REINTEGRATIONS, params: EntreeSortieParametrage.REINTEGRATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'reintegrations/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un bordereau de réintégrations',
    component: EntreeSortieComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.MOUVEMENTS, submenu: TopbarSubmenu.REINTEGRATIONS, droit: CodeDroit.GESTION_REINTEGRATIONS, params: EntreeSortieParametrage.REINTEGRATION},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-inventaires',
    title: appProperties.nomApplication + ' - Liste des inventaires',
    component: ListeInventairesComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.INVENTAIRES, droit: CodeDroit.GESTION_INVENTAIRES}
  },
  {
    path: 'creation-inventaire',
    title: appProperties.nomApplication + ' - Création d\'un inventaire',
    component: InventaireComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.INVENTAIRES, droit: CodeDroit.GESTION_INVENTAIRES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'inventaire/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un inventaire',
    component: InventaireComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.INVENTAIRES, droit: CodeDroit.GESTION_INVENTAIRES},
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'liste-fournisseurs',
    title: appProperties.nomApplication + ' - Liste des fournisseurs',
    component: ListeFournisseursComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.FOURNISSEURS, droit: [CodeDroit.GESTION_FOURNISSEURS_GLOBALE, CodeDroit.GESTION_FOURNISSEURS_LOCALE]}
  },
  {
    path: 'fournisseur/:id',
    title: appProperties.nomApplication + ' - Consultation d\'un fournisseur',
    component: FournisseurComponent,
    canActivate: [canActivateRouteGuard],
    data: {menu: TopbarMenu.PARAMETRAGES, submenu: TopbarSubmenu.FOURNISSEURS, droit: [CodeDroit.GESTION_FOURNISSEURS_GLOBALE, CodeDroit.GESTION_FOURNISSEURS_LOCALE]}
  },
  {path: '**', redirectTo: '/choix-crous'}
];

@NgModule({
  // Option onSameUrlNavigation : pour recharger une page déjà chargée
  // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    canceledNavigationResolution: 'computed' // Référence : https://stackoverflow.com/a/76027002
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
