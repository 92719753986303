import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {Crous} from "../../model/epona-api/crous";
import {environment} from "../../../environments/environment";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";

@Injectable({
  providedIn: 'root'
})
export class CrousService {

  private env = environment;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getCrous(idCrous: number, fields: string): Observable<Crous> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<Crous>(this.env.eponaApiUrl + '/crous/' + idCrous, {params});
  }
  getCurrentCrous(fields: string): Observable<Crous> {
    return this.getCrous(LocalStorageHelper.getIdEtablissement(), fields);
  }
}
